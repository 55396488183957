import axios from '../node_modules/axios/index'

customElements.define('image-grid-mini', class extends HTMLElement {
    mobile = window.innerWidth < 768
    images:any
    
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                img {
                    height: 100%;
                    display: inline-block;
                    margin: 10px;
                    box-shadow: 3px 4px 11px 0 #00000033;
                    border: solid 1px #e7e7e7;
                }

                img:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                .row {
                    margin-bottom: 20px;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    img {
                        width: calc(100% - 40px);
                    }
                }
            </style>

            <div id='container'></div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        window.addEventListener('resize', () => {
            if (window.innerWidth >= 768 && this.dataset.display === 'lowres') this.setGrid(300, 3)
            else if (window.innerWidth >= 768) this.setGrid(300, 2)
            else this.mobileImage(3)
        })

        this.fetchImages((this.dataset.url as string))
    }

    fetchImages(url:string) {
        axios.get(`https://api2.cartoonstock.com/${url}`).then(res => {
            if (!url) return
            // if (url.includes('suggestions') || url.includes('recently-viewed')) {
            if (this.dataset.display === 'personal' && (this.getRootNode() as any).host) {
                // Need to check if user has suggestions/recently viewed or not
                if (typeof res.data === 'string' || res.data.length < 10) return (this.getRootNode() as any).host.noData = url
                else {
                    this.images = res.data
                    if (!this.mobile) this.setGrid(300, 3)
                    else this.mobileImage(3);
                    (this.getRootNode() as any).host.hasData = url
                }
            } else if (this.dataset.display === 'lowres') {
                this.images = res.data
                if (!this.mobile) this.setGrid(300, 3)
                else this.mobileImage(3)
            } else {
                // Standard image set, no checks needed
                this.images = res.data
                if (!this.mobile) this.setGrid(300, 2)
                else this.mobileImage(3)
            }
        })
    }

    // Desktop or tablet, create grid
    setGrid(targetHeight:number, rowLimit:number) {
        if (typeof this.images === 'string') return
        const cartoons = this.images
    
        // Calcualte adjusted image widths
        cartoons.forEach((image:any, i:number) => cartoons[i].width = (targetHeight / image.files.preview.length)  * image.files.preview.width)

        let html = '';
        (this.shadowRoot?.querySelector('#container') as HTMLElement).innerHTML = ''
        let widthCounter = 0 // Determine when width fills a row
        const heights: any[] = [] // Store height of each row
        let imageCount = 0 // Account for each image margin
        let rowCount = 0 // Limit amount of rows
        html = /*html*/ `<div class='row'>`
        this.images.forEach((image:any) => {
            if (rowCount === rowLimit) return
            html += /*html*/ `<img class='cartoon-image' data-search-id='${image.search_id}' src='${image.image_url}' alt='${image.title}'>`
            imageCount++
            widthCounter += image.width
            if (widthCounter >= (this.shadowRoot?.querySelector('#container') as HTMLElement).offsetWidth) {
                heights.push(((this.shadowRoot?.querySelector('#container') as HTMLElement).offsetWidth - (this.mobile ? 20 : 60) - (imageCount * 20)) / (widthCounter / targetHeight))
                widthCounter = 0
                imageCount = 0
                html += /*html*/ `
                    </div>
                    <div class='row'>
                `
                rowCount++
            }
        })
        html += '</div>';

        (this.shadowRoot?.querySelector('#container') as HTMLElement).innerHTML = html;

        // Remove last row if empty
        if ((this.shadowRoot?.querySelectorAll('.row:last-child')[0] as HTMLElement).childNodes.length === 1) (this.shadowRoot?.querySelectorAll('.row:last-child')[0] as HTMLElement).remove();

        // Set row heights, if last row set as target height
        this.shadowRoot?.querySelectorAll('.row').forEach((row:any, i:number) => {
            if (heights[i]) {
                row.dataset.height = heights[i]
                row.style.height = `${heights[i]}px`
            }
            else {
                row.dataset.height = targetHeight
                row.style.height = `${targetHeight}px`
            }
        });

        // Remove last row if not packed full
        if ((this.shadowRoot?.querySelector('.row:last-child') as HTMLElement).dataset.height === targetHeight.toString()) (this.shadowRoot?.querySelector('.row:last-child') as HTMLElement).remove();

        // Image click event listeners
        this.shadowRoot?.querySelectorAll('.cartoon-image').forEach((image:any) => image.addEventListener('click', ()  => location.href = 'https://www.cartoonstock.com/cartoon?searchID=' + image.dataset.searchId));
        

        (this.shadowRoot?.querySelector('#container') as HTMLElement).style.display = 'flex'
    }

    // On mobile, set one image only
    mobileImage(limit:number) {
        (this.shadowRoot?.querySelector('#container') as HTMLElement).innerHTML = '';
        let html = ''
        this.images.forEach((image:any, i:number) => {
            if (i < limit) html += /*html*/ `<img class='cartoon-image' data-search-id='${image.search_id}' src='${image.image_url}' alt='${image.title}'>`
        });
        (this.shadowRoot?.querySelector('#container') as HTMLElement).innerHTML = html
        this.shadowRoot?.querySelectorAll('.cartoon-image').forEach((image:any) => image.addEventListener('click', ()  => location.href = 'https://localhost:9000/cartoon?searchID=' + image.dataset.searchId));
    }

    set regenGrid(data:{targetHeight:number, rowLimit:number}) {
        if (this.mobile) {
            this.mobileImage(25)
        } else this.setGrid(data.targetHeight, data.rowLimit)
    }
})
import axios from '../node_modules/axios/index'

customElements.define('license-packs-banner', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: var(--font-main);
                    width: 100%;
                    height: 50px;
                    background-color: var(--link-blue);
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.27px;
                }

                a {
                    text-decoration: none;
                    font-size: 13px;
                    font-weight: 600;
                    padding: 8px;
                    letter-spacing: 0.22px;
                    text-align: center;
                    color: #fff;
                    border-radius: 7px;
                    border: solid 2px #fff;
                    margin-left: 12px;
                }

                a:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #container {
                        height: 40px;
                        font-size: 12px;
                    }

                    a {
                        padding: 5px 9px;
                        font-size: 10px;
                        border: solid 1px #fff;
                    }
                }
            </style>

            <div id='container'>
                <p>Save money & time with License Packs</p>
                <a href='https://www.cartoonstock.com/pricing'>Find out more</a>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        
    }
})
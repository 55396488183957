import axios from '../node_modules/axios/index'

customElements.define('trending-searches', class extends HTMLElement {
    trending = ['cats', 'dogs', 'cats and dogs', 'something with a bit of a long title', 'cats', 'dogs', 'cats and dogs', 'something with a bit of a long title', 'cats', 'dogs', 'cats and dogs', 'something with a bit of a long title']
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 3px 4px 11px 0 #0000000a;
                    border: solid 1px #e7e7e7;
                    background-color: #fff;
                    color: #3d3d3d;
                }

                #header {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    margin: 50px 0 5px 0;
                }

                #subheader {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    color: #808080;
                    margin-bottom: 40px;
                }

                #trending {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 900px;
                    height: 120px;
                    margin-bottom: 70px;
                }

                #trending p {
                    margin: 6px;
                    text-align: center;
                    width: 200px;
                }

                #trending p:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #header {
                        font-size: 13px;
                    }

                    #subheader {
                        font-size: 11px;
                        padding: 0 20px;
                    }

                    #trending {
                        width: calc(100% - 40px);
                        height: auto;
                    }

                    #trending p {
                        width: 100%;
                    }
                }
            </style>

            <div id='container'>
                <h1 id='header'>Trending searches</h1>
                <p id='subheader'>Our most popular search terms over the past week</p>

                <div id='trending'></div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        if (this.dataset.type === 'store') {
            axios.get('https://api2.cartoonstock.com/features/trending-store').then(res => {
                let html = ''
                res.data.forEach((item:any, i:number) => i < 12 ? html += /*html*/ `<p data-keyword='${item.keyword}'>${item.keyword}</p>` : '');
                (this.shadowRoot?.querySelector('#trending') as HTMLElement).innerHTML = html
    
                this.shadowRoot?.querySelectorAll('#trending p').forEach((tag:any) => tag.addEventListener('click', () => window.eventBus.dispatchEvent('search', { keyword: tag.dataset.keyword })))
            });
            (this.shadowRoot?.querySelector('#header') as HTMLElement).innerText = 'Trending store searches';
            (this.shadowRoot?.querySelector('#subheader') as HTMLElement).innerText = 'Our most popular store search terms over the past week';
        } else {
            axios.get('https://api2.cartoonstock.com/features/trending').then(res => {
                let html = ''
                res.data.forEach((item:any, i:number) => i < 12 ? html += /*html*/ `<p data-keyword='${item.keyword}'>${item.keyword}</p>` : '');
                (this.shadowRoot?.querySelector('#trending') as HTMLElement).innerHTML = html
    
                this.shadowRoot?.querySelectorAll('#trending p').forEach((tag:any) => tag.addEventListener('click', () => window.eventBus.dispatchEvent('search', { keyword: tag.dataset.keyword })))
            })
        }
    }
})
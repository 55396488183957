import './search-options'
import './search-input'

customElements.define('search-mobile', class SearchMobile extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    z-index: 3;
                }

                form {
                    box-shadow: 3px 5px 11px 0 rgba(0, 0, 0, 0.2);
                    margin: 20px 20px 0 20px;
                }

                #search {
                    display: flex;
                    flex-direction: row;
                }

                #search-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #fff;
                    padding: 10px;
                    border-top: 1px solid #979797;
                    border-left: 1px solid #979797;
                    border-bottom: 1px solid #979797;
                    border-right: none;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                #search-icon img {
                    width: 20px;
                    height: 20px;
                    filter: invert(100%) sepia(8%) saturate(26%) hue-rotate(263deg) brightness(107%) contrast(100%);
                }

                #filters-btn-mobile {
                    font-family: var(--font-main);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    width: 122px;
                    padding: 0 16px;
                    border-top: 1px solid #979797;
                    border-right: 1px solid #979797;
                    border-bottom: 1px solid #979797;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    background-color: #efefef;
                    border-left: none;
                    outline: none;
                }

                #filters-btn-mobile img {
                    margin-right: 10px;
                }

                #search-menu {
                    display: flex;
                    flex-flow: column;
                    background-color: #fff;
                    width: 100%;
                    height: 0;
                    transition: height 0.2s ease;
                }

                #close {
                    font-family: var(--font-main);
                    color: #3d3d3d;
                    display: flex;
                    align-items: center;
                    margin: 30px auto;
                    background-color: transparent;
                    font-size: 14px;
                    border: none;
                    outline: none;
                }

                #close img {
                    margin-left: 10px;
                }

                #dropdown-button {
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    font-family: var(--font-main);
                    border: solid 1px #979797;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    width: 162px;
                    font-size: 16px;
                    padding: 15px 0 0 26px;
                    background-color: #fff;
                    outline: none;
                }

                #dropdown-button img {
                    position: absolute;
                    right: 10px;
                    transition: transform 0.1s linear;
                    margin-top: -2px;
                }

                #dropdown {
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    background-color: #fff;
                    overflow: hidden;
                    transition: all 0.2s ease-in-out;
                    width: 160px;
                    height: 0;
                    margin-top: 50px;
                    font-size: 14px;
                    text-align: start;
                    border: 1px solid #979797;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    box-shadow: 3px 5px 11px 0 #00000020;
                }

                #dropdown span:first-child {
                    padding-top: 20px;
                }

                #dropdown span {
                    padding: 0 0 12px 30px;
                    text-decoration: none;
                    color: #3d3d3d;
                    font-size: 16px;
                }

                #dropdown span:last-child {
                    padding-bottom: 20px;
                }

                #dropdown span.header {
                    padding-left: 20px;
                    font-weight: bold;
                }

                #dropdown span:hover {
                    cursor: pointer;
                    color: var(--cs-orange);
                }

                .input-open {
                    border-bottom: none !important;
                    border-bottom-right-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }
            </style>

            <div id='container'>
            <form>
                <div id='search'>
                <div id='search-icon'><img src='https://assets.cartoonstock.com/images/search.svg' alt='search'></div><search-input data-search='${this.dataset.search}' data-view='mobile'></search-input>${this.dataset.search === 'true' ? "<button id='filters-btn-mobile'><img id='filter-icon' src='https://assets.cartoonstock.com/images/tune.svg' alt='filters'> Filters</button>" : ''}
                </div>
                <search-options
                    data-options='[
                        {
                            "name": "All Images",
                            "header": true,
                            "value": "images"
                        },
                        {
                            "name": "Vintage",
                            "header": false,
                            "value": "vintage"
                        },
                        {
                            "name": "All Products",
                            "header": true,
                            "value": "store"
                        },
                        {
                            "name": "Gifts for her",
                            "header": false,
                            "value": "for_her"
                        },
                        {
                            "name": "Gifts for him",
                            "header": false,
                            "value": "for_him"
                        }
                    ]'
                ></search-options>
            </form>
                <div id='search-menu'>
                    <button id='close'>Close <img src='https://assets.cartoonstock.com/images/close.svg' alt='close menu'></button>
                </div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        const menu = (this.shadowRoot?.querySelector('#search-menu') as HTMLElement)

        // Detect window resize for menu resizing/adjustments
        window.addEventListener('resize', () => {
            if (this.dataset.open === 'true') {
                menu.style.transition = 'none'
                menu.style.height = `${window.innerHeight}px`;
                setTimeout(() => {
                    menu.style.transition = 'height 0.2s ease'
                }, 1000);
            }

            if (window.innerWidth > 767) (this.shadowRoot?.host as HTMLElement).style.display = 'none'
            else (this.shadowRoot?.host as HTMLElement).style.display = 'block'
        })

        // Open search menu
        this.shadowRoot?.querySelector('search-input')?.addEventListener('focus', () => {
            if (this.shadowRoot?.querySelector('#filters-btn-mobile')) (this.shadowRoot?.querySelector('#filters-btn-mobile') as HTMLElement).style.display = 'none'
            menu.style.height = `${window.innerHeight}px`;
            this.dataset.open = 'true';
            (this.shadowRoot?.querySelector('form') as HTMLElement).style.transform = 'translateY(190px)';
            (document.querySelector('nav-mobile') as HTMLElement).style.height = '0px';
            this.style.bottom = '0';
            (this.shadowRoot?.querySelector('search-input')?.shadowRoot?.querySelector('input') as HTMLElement).classList.add('input-open');
            (this.shadowRoot?.querySelector('#search-icon') as HTMLElement).classList.add('input-open');
            (this.shadowRoot?.querySelector('search-options')?.shadowRoot?.querySelector('#dropdown-button') as HTMLElement).style.display = 'flex';
            (this.shadowRoot?.querySelector('search-options')?.shadowRoot?.querySelector('.divider') as HTMLElement).style.display = 'block';
            (this.shadowRoot?.querySelector('search-input') as any).openSearch
        })

        // Close search menu
        this.shadowRoot?.querySelector('#close')?.addEventListener('click', () => {
            this.dataset.open = 'false'
            if (window.innerWidth < 767) {
                (this.shadowRoot?.querySelector('search-input')?.shadowRoot?.querySelector('#search-suggestions') as HTMLElement).style.display = 'none'
            }
            (this.shadowRoot?.querySelector('search-options')?.shadowRoot?.querySelector('#dropdown-button') as HTMLElement).style.display = 'none';
            (this.shadowRoot?.querySelector('#search-icon') as HTMLElement).classList.remove('input-open');
            (this.shadowRoot?.querySelector('search-options')?.shadowRoot?.querySelector('.divider') as HTMLElement).style.display = 'none';
            menu.style.height = '0';
            (this.shadowRoot?.querySelector('search-input')?.shadowRoot?.querySelector('input') as HTMLElement).classList.remove('input-open');
            if (this.shadowRoot?.querySelector('#filters-btn-mobile')) (this.shadowRoot?.querySelector('#filters-btn-mobile') as HTMLElement).style.display = 'flex';
            (this.shadowRoot?.querySelector('form') as HTMLElement).style.transform = 'translateY(0)';
            (document.querySelector('nav-mobile') as HTMLElement).style.height = 'auto';
            this.style.bottom = '70px';
        })

        // Close search options
        this.shadowRoot?.addEventListener('click', e => {
            if (e.target != this.shadowRoot?.querySelector('search-options')) {
                (this.shadowRoot?.querySelector('search-options') as any).close
            }
        })

        // Open filters
        this.shadowRoot?.querySelector('#filters-btn-mobile')?.addEventListener('click', e => {
            e.preventDefault();
            (document.querySelector('search-filters-menu') as HTMLElement).style.width = '100%';
            (document.querySelector('search-filters-menu') as HTMLElement).style.display = 'block';
            document.querySelector('search-filters-menu')?.shadowRoot?.querySelectorAll('search-filters-dropdown').forEach((dropdown:any) => {
                if (dropdown.dataset.open === 'true') dropdown.open
            })
            setTimeout(() => {
                (document.querySelector('search-filters-menu') as HTMLElement).style.transform = 'translateY(0)';
            }, 10);
            (this.shadowRoot?.host as HTMLElement).style.display = 'none'
        })
    }
})
import './search-options'
import './search-input'

customElements.define('search-desktop', class extends HTMLElement {
    secondaryMobileCSS = `
        #search-btn {
            display: none;
        }
    `

    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                :host {
                    position: ${this.dataset.page === 'store' ? '' : 'sticky'};
                    width: 100%;
                    top: -1px;
                    padding: 21px 0 20px 0;
                    z-index: 1;
                    background-color: ${this.dataset.backgroundColor};
                }
                
                #container {
                    font-family: var(--font-main);
                    display: flex;
                    justify-content: space-between;
                    background-color: ${this.dataset.backgroundColor};
                    /* ${this.dataset.page === 'directory' ? 'padding: 20px 20px 0 20px' : 'padding: 0 20px 0 0'}; */
                    ${this.dataset.page === 'directory' ? 'padding: 20px 20px 0 20px' : this.dataset.page === 'search' ? 'padding: 0 20px 0 0' : this.dataset.page === 'cartoonists' ? 'padding: 0 35px 20px 35px' : 'padding: 0'};
                    ${this.dataset.page === 'cart' ? 'margin: 20px 30px; padding: 0;' : ''}
                }

                #filters-btn {
                    display: ${this.dataset.search === 'true' && window.innerWidth > 1024 ? 'flex' : 'none'};
                    width: 230px;
                    margin-right: 30px;
                    border-top-right-radius: 5px;
                    font-family: var(--font-main);
                    align-items: center;
                    background-color: #efefef;
                    border-left: none;
                    border-top: 1px solid #afafaf;
                    border-right: 1px solid #afafaf;
                    border-bottom: 1px solid #afafaf;
                    border-top-right-radius: 5px;
                    height: 50px;
                    font-size: 15px;
                    padding-left: 30px;
                    outline: none;
                    transition: width 0.2s ease-in-out;
                }

                #filter-icon {
                    margin-right: 10px;
                    filter: invert(19%) sepia(0%) saturate(1141%) hue-rotate(197deg) brightness(102%) contrast(85%);
                }

                #chevron {
                    margin: 0 18px 0 85px;
                    transition: transform 0.2s linear;
                }

                button {
                    height: 51px;
                }

                button:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                form {
                    display: flex;
                    align-items: center;
                    /* margin-right: 30px; */
                    width: 100%;
                }

                label {
                    position: absolute;
                    left: -10000px;
                    top: auto;
                    width: 1px;
                    height: 1px;
                    overflow: hidden;
                }

                #search-btn {
                    height: 51px;
                    border: none;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    background-color: #3d3d3d;
                    padding: 10px 20px;
                    outline: none;
                }

                #search-btn img {
                    width: 20px;
                    height: 20px;
                }

                #filters-btn-tablet {
                    display: none;
                    font-family: var(--font-main);
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    width: 122px;
                    padding: 0 16px;
                    border-top: 1px solid #979797;
                    border-right: 1px solid #efefef;
                    border-bottom: 1px solid #979797;
                    border-left: 1px solid #979797;
                    outline: none;
                }

                #filters-btn-tablet img {
                    margin-right: 10px;
                }

                #favorites-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: var(--font-main);
                    border: solid 1px #979797;
                    border-radius: 5px;
                    background-color: #fff;
                    width: 190px;
                    padding: 15px;
                    font-size: 16px;
                }

                #favorites-btn img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {

                }

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {
                    :host {
                        background-color: ${this.dataset.search === 'true' ? '#fafafa' : 'transparent'};
                    }

                    #container {
                        padding: 0;
                    }

                    form {
                        margin: 0 30px;
                    }

                    #favorites-btn {
                        display: none;
                    }

                    #filters-btn-tablet {
                        display: flex;
                    }
                }

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #filters-btn-tablet {
                        display: none;
                    }

                    ${this.dataset.secondary === 'true' ? this.secondaryMobileCSS : ''}
                }
            </style>

            <div id='container'>
                <form>
                    <button id='filters-btn'><img id='filter-icon' src='https://assets.cartoonstock.com/images/tune.svg' alt='filters'> Filters <img id='chevron' src='https://assets.cartoonstock.com/images/chevron-left.svg' alt='chevron'></button>
                    <label for='options'>Options</label>
                    <label for='search'>Search</label>
                    <search-input data-search='${this.dataset.search}' data-view='desktop' data-secondary='${this.dataset.secondary}'></search-input>
                    ${this.dataset.search === 'true' ? "<button id='filters-btn-tablet'><img id='filter-icon' src='https://assets.cartoonstock.com/images/tune.svg' alt='filters'> Filters</button>" : ''}
                    <button id='search-btn'><img src='https://assets.cartoonstock.com/images/search.svg' alt='search'></button>
                </form>
                <!-- <button id='favorites-btn'><img src='https://upload.wikimedia.org/wikipedia/commons/d/d0/Heart_font_awesome.svg' alt='my favorites'> My Favorites</button> -->
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        // Detect when sticky (scrolling)
        const observer = new IntersectionObserver( 
            ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
            {threshold: [1]}
        )
        observer.observe(this)

        // Search button pressed
        this.shadowRoot?.querySelector('#search-btn')?.addEventListener('click', () => {
            (this.shadowRoot?.querySelector('search-input') as any).search
        })

        // Search submission / save to history
        this.shadowRoot?.querySelector('form')?.addEventListener('submit', e => {
            e.preventDefault();
            (this.shadowRoot?.querySelector('search-input') as any).search
        })

        // Close search options
        this.shadowRoot?.addEventListener('click', e => {
            if (e.target != this.shadowRoot?.querySelector('search-options')) {
                (this.shadowRoot?.querySelector('search-options') as any).close
            }
        });

        // Open filters menu desktop
        this.shadowRoot?.querySelector('#filters-btn')?.addEventListener('click', e => {
            e.preventDefault();
            window.eventBus.dispatchEvent('filters-btn-desktop')
        })

        // Open filters menu tablet
        this.shadowRoot?.querySelector('#filters-btn-tablet')?.addEventListener('click', e => {
            e.preventDefault();
            (document.querySelector('search-filters-menu') as any)?.openTablet
        })
    }
})
import axios from '../node_modules/axios/index'

customElements.define('search-input', class extends HTMLElement {
    keyword:any = null
    dropdown = ''
    secondaryMobileCSS = `
        input {
            border: 1px solid #979797;
            border-radius: 5px;
            margin-bottom: 20px;
        }

        input::placeholder {
            font-size: 12px;
        }
    `

    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                :host {
                    width: 100%;
                }

                input {
                    font-family: var(--font-main);
                    padding: 15px 25px;
                    width: 100%;
                    font-size: 16px;
                    border: solid 1px #979797;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    outline: none;
                }

                #suggestions-wrapper {
                    position: relative;
                }

                #search-suggestions {
                    position: absolute;
                    box-sizing: border-box;
                    width: 100%;
                    display: none;
                    height: 400px;
                    overflow: hidden;
                    border-bottom: 1px solid #979797;
                    border-left: 1px solid #979797;
                    border-right: 1px solid #979797;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    box-shadow: 3px 5px 11px 0 #00000020;
                    margin-left: -1px;
                    z-index: 10;
                }

                #search-suggestions p {
                    margin: 0 0 20px 0;
                    text-align: start;
                }

                #recent-images-container {
                    display: none;
                    width: 65%;
                    background-color: #fafafa;
                    padding: 20px;
                    overflow-y: scroll;
                }

                #recent-images {
                    display: flex;
                    justify-content: space-evenly;
                    flex-wrap: wrap;
                    align-items: center;
                    background-color: #fafafa;
                }

                #recent-images-container img {
                    max-width: 188px;
                    margin: 10px;
                }

                #suggestions {
                    display: none;
                    flex-direction: column;
                    width: 65%;
                    background-color: #fafafa;
                    padding: 20px;
                    text-align: start;
                }

                #suggestions div {
                    margin-bottom: 12px;
                    font-weight: bold;
                }

                #suggestions div:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                .unbold {
                    font-weight: 400;
                }

                #recent-searches {
                    display: flex;
                    flex-direction: column;
                    width: 35%;
                    background-color: #fff;
                    padding: 20px 30px 30px 44px;
                    text-align: start;
                }

                #recent-searches span {
                    margin: 0 0 12px 10px;
                    font-weight: 300;
                }

                #recent-searches span:hover {
                    cursor: pointer;
                    font-weight: 400;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {
                    #recent-images {
                        display: none;
                    }

                    #suggestions {
                        width: 100%;
                    }

                    #recent-searches {
                        width: 100%;
                        padding: 20px;
                    }
                }

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {
                    #suggestions, #recent-searches {
                        width: 100%;
                    }
                }

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #suggestions-wrapper {
                        position: static;
                    }

                    input {
                        box-sizing: border-box;
                        font-size: 15px;
                        min-width: 0;
                        width: 100%;
                        padding: 12px 0 12px 12px;
                        outline: none;
                        border-top: 1px solid #979797;
                        border-right: 1px solid #979797;
                        border-bottom: 1px solid #979797;
                        border-left: none;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: ${this.dataset.search === 'true' ? '0' : '5px'};
                        border-bottom-right-radius: ${this.dataset.search === 'true' ? '0' : '5px'};
                    }

                    #search-suggestions {
                        border: none;
                        border-radius: 0;
                        box-shadow: none;
                        left: 0;
                        margin-top: 80px;
                    }

                    #suggestions {
                        background-color: #fff;
                    }

                    .input-open {
                        border-bottom: none !important;
                        border-bottom-right-radius: 0 !important;
                        border-bottom-left-radius: 0 !important;
                    }

                    ${this.dataset.secondary === 'true' ? this.secondaryMobileCSS : ''}
                }
            </style>

            <input type='text' name='keyword' id='search' title='Search Keywords' placeholder='Search by keywords or cartoonist' autocomplete='off'>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        const input = (this.shadowRoot?.querySelector('input') as HTMLInputElement)
        const urlQuery = new URLSearchParams(window.location.search).get('keyword')

        // Helper function to determine which version of this component is active (performing searches)
        const isActive = () => {
            return window.innerWidth > 767 && this.dataset.view === 'desktop' || window.innerWidth < 767 && this.dataset.view === 'mobile'
        }

        // Receive search from other components
        window.eventBus.addEventListener('search', (e:CustomEvent) => {
            if (isActive()) {
                if (e.detail.keyword) input.value = e.detail.keyword
                this.search
            }
        })

        // Listen for dropdown option selected
        window.eventBus.addEventListener('search-dropdown', (e:CustomEvent) => {
            this.dropdown = e.detail.value
        })

        // Apply some filters and update placeholder (from /cartoonists and /publications for secondary search)
        window.eventBus.addEventListener('secondary-search', (e:CustomEvent) => {
            if (this.dataset.secondary === 'true') {
                (this.shadowRoot?.querySelector('input') as HTMLInputElement).placeholder = e.detail.placeholder

                localStorage.setItem('filters', JSON.stringify({
                    sort: 'best_match',
                    category: null,
                    caption: null,
                    collection: e.detail.filter_key === 'collection' ? [e.detail.filter_value] : [],
                    cartoonist: e.detail.filter_key === 'cartoonist' ? e.detail.filter_value : null,
                    keyword_exclude: [],
                    color: null,
                    orientation: null,
                    image_min_width: 0,
                    image_min_height: 0,
                    publication_date_start: null,
                    publication_date_end: null,
                    exclude_editorial_use_only: false,
                    exclude_locally_restricted: true,
                    store_product: [],
                    store_recipient: null,
                    store_occasion: null,
                    store_seasonal: null,
                    page: 1,
                    search_type: null,
                    store_homepage: null
                }))
            }
        })

        // Reset all filters event listener
        // window.eventBus.addEventListener('clear-all-filters', () => this.resetFilters())

        // Listen for filter changes and get from localStorage
        window.eventBus.addEventListener('filters-changed', () => {
            if (isActive()) {
                this.search
            }
        })
        
        // Set search input value from URL param if present
        input.value = urlQuery || ''

        // Perform search if landing on page with a query, make sure only active component performs search
        if (isActive()) {
            // if (this.dataset.search === 'true' && urlQuery) {
            if (this.dataset.search === 'true') {
                this.search
            }
        }

        // Press enter to submit search
        input?.addEventListener('keypress', (e:any) => {
            if (e.key === 'Enter') this.search
        })
    }

    get search() {
        const keyword = (this.shadowRoot?.querySelector('input') as HTMLInputElement).value || ''
        return location.href = `https://www.cartoonstock.com/search?type=images&keyword=${keyword}`
        if (!this.keyword) this.keyword = keyword

        // First, check if searching via an image ID, if so take them to the low res page
        // Match csl_catref, convert to search_id
        if (keyword !== '' && keyword.match(/[A-Z]{3,4}[0-9]{1,9}/i)) return axios.get(`https://api2.cartoonstock.com/id-convert/${keyword}`).then(res => location.href = `/cartoon?searchID=${res.data.search_id}`)

        // Match search_id
        if (keyword !== '' && keyword.match(/[A-Z]{2}[0-9]{1,9}/i)) return location.href = `/cartoon?searchID=${keyword}`

        // Remove keyword from url if blank
        const url:any = new URL(window.location.toString());
        if (keyword === '') {
            url.searchParams.delete('keyword')
            window.history.pushState({}, '', url)
        }

        const filters = JSON.parse(localStorage.getItem('filters') || '[]')

        // Reset page on new keyword search
        if (keyword !== this.keyword) filters.page = 1

        // Add page to url
        url.searchParams.set('page', filters.page)
    }
})
import axios from '../node_modules/axios/index'

customElements.define('nav-main', class extends HTMLElement {
    logged_in = false

    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    padding: 0 30px;
                    display: grid;
                    grid-template-columns: min-content max-content 1fr;
                    grid-template-areas: 'logo nav-pages nav-user-desktop';
                    box-shadow: 0 2px 11px 0 #dadada;
                }

                .logo {
                    display: flex;
                    margin: 12px 20px 15px 0;
                    justify-content: center;
                    align-items: center;
                }

                #logo-cc, #logo {
                    grid-area: logo;
                    height: 40px;
                }

                .divider {
                    width: 1px;
                    height: 50px;
                    background-color: #e7e7e7;
                    margin: 0 20px;
                }

                #nav-user-tablet {
                    display: none;
                    grid-area: nav-user-tablet;
                    z-index: 0;
                }

                #nav-user-tablet img {
                    margin-bottom: 5px;
                    filter: invert(19%) sepia(0%) saturate(1141%) hue-rotate(197deg) brightness(102%) contrast(85%);
                }

                #nav-pages, #nav-user-desktop, #cart-quantity {
                    display: flex;
                    align-items: center;
                }

                #nav-pages {
                    grid-area: nav-pages;
                    justify-content: start;
                }

                #nav-user-desktop {
                    grid-area: nav-user-desktop;
                    justify-content: flex-end;
                }

                a {
                    text-decoration: none;
                    color: #3d3d3d;
                    font-weight: 400;
                }
                
                a:not(.logo) {
                    position: relative;
                    margin-left: 30px;
                    padding: 22px 0;
                }

                span {
                    font-size: 14px;
                    position: absolute;
                    top: -10px;
                    right: -6px;
                    background-color: #d43b3b;
                    font-weight: bold;
                    color: #fff;
                    padding: 2px 6px;
                    border-radius: 12px;
                }

                #my-account {
                    display: none;
                }

                button {
                    font-family: var(--font-main);
                    margin-left: 30px;
                    border: none;
                    color: #3d3d3d;
                    font-weight: 400;
                    background-color: transparent;
                    font-size: 100%;
                    outline: none;
                }

                button:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                .option {
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                }

                .selected {
                    font-weight: 500;
                    border-top: 10px solid var(--cs-orange);
                    color: var(--cs-orange);
                }

                #cart-quantity {
                    display: flex;
                    justify-content: center;
                    background-color: #000;
                    color: #fff;
                    font-weight: 500;
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    font-size: 12px;
                    margin: 0 0 5px 10px;
                }

                #cart-quantity-tablet {
                    padding: 1px 6px;
                    background-color: #d43b3b;
                }

                #sign-up {
                    border: solid 1px #979797;
                    border-radius: 5px;
                    padding: 10px;
                    margin-left: 20px;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {
                    #contact, #pricing {
                        display: none;
                    }
                }

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {
                    #container {
                        margin: 0;
                        grid-template-columns: 1fr 1fr max-content;
                        grid-template-rows: 1fr 0.5fr;
                        grid-template-areas:
                            'logo . nav-user-tablet'
                            'nav-pages nav-pages nav-pages';
                    }

                    #logo {
                        margin: 15px 30px;
                    }

                    a, button {
                        margin: 0;
                        line-height: 1;
                    }

                    .selected {
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid var(--cs-orange);
                    }

                    #my-account {
                        display: none;
                    }

                    #nav-user-desktop {
                        display: none;
                    }

                    #nav-pages {
                        display: flex;
                        justify-content: space-evenly;
                        border-top: solid 1px #ebebeb;
                    }

                    #nav-pages a {
                        text-align: center;
                        width: 100%;
                        margin: 0;
                    }

                    #nav-user-tablet {
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        margin: 15px 30px;
                    }

                    #nav-user-tablet a, #nav-user-tablet button {
                        margin: 0 10px;
                        height: auto;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #container {
                        grid-template-columns: 0.1fr 1fr;
                        grid-template-rows: 1fr;
                        grid-template-areas:
                            'logo nav-pages';
                    }

                    #nav-user-tablet {
                        display: none;
                    }

                    #nav-pages {
                        height: 57px;
                    }

                    a:not(.logo) {
                        padding: 16px 0;
                        font-size: 15px;
                    }

                    #logo-cc {
                        content:url('https://assets.cartoonstock.com/images/cc_logo_icon.png');
                        width: 44px;
                        height: 44px;
                        margin: 4px 0;
                    }

                    #logo {
                        content:url('https://assets.cartoonstock.com/images/logo-icon-200px.png');
                        width: 44px;
                        height: 44px;
                        margin: 4px 0;
                    }

                    .selected {
                        border-top: 6px solid var(--cs-orange);
                        border-bottom: 6px solid transparent;
                    }
                }
            </style>

            <div id='container'>
                <a class='logo' href='https://www.cartoonstock.com' title='Home'><img id='logo-cc' src='https://assets.cartoonstock.com/images/cc_logo_small.png' alt='cartoon collections logo'> <div class='divider'></div> <img id='logo' src='https://assets.cartoonstock.com/images/logo-500px.png' alt='cartoonstock logo'></a>
                <div id='nav-pages'>
                    <a href='https://www.cartoonstock.com' id='cartoons' class='option'>Cartoons</a>
                    <a href='https://www.cartoonstockgifts.com?utm_source=cartooncollections&utm_medium=link&utm_campaign=cartooncollections-topnavlink' id='store' class='option'>Store</a>
                    <a href='https://www.cartoonstock.com/blog' id='blog' class='option'>Blog</a>
                </div>

                <div id='nav-user-desktop'>
                    <a id='contact' href='https://www.cartoonstock.com/contact'>Contact</a>
                    <!-- <a id='pricing' href=''>Pricing</a> -->
                    <a href='https://www.cartoonstock.com/cart'>Cart</a><div id='cart-quantity'></div>
                    <button id='log-in-btn'>Log In</button>
                    <button id='sign-up-btn'>Sign Up</button>
                    <button id='my-account'>My Account</button>
                </div>

                <div id='nav-user-tablet'>
                    <!-- <a href='#'><img src="https://assets.cartoonstock.com/images/favorites.svg" alt='favorites'>Favorites</a> -->
                    <a href='https://www.cartoonstock.com/cart'><img src="https://assets.cartoonstock.com/images/shopping-cart.svg" alt='cart'><span id='cart-quantity-tablet'></span>Cart</a>
                    <button><img src="https://assets.cartoonstock.com/images/user.svg" alt='log in'>Log In</button>
                    <button id='menu'><img src="https://assets.cartoonstock.com/images/menu.svg" alt='open menu'>Menu</button>
                </div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        this.shadowRoot?.querySelector('#menu')?.addEventListener('click', () => location.href = 'https://www.cartoonstock.com')

        // Log in event listener
        this.shadowRoot?.querySelector('#log-in-btn')?.addEventListener('click', () => location.href = 'https://www.cartoonstock.com')

        // Sign up event listener
        this.shadowRoot?.querySelector('#sign-up-btn')?.addEventListener('click', () => location.href = 'https://www.cartoonstock.com')

        // My account event listener
        this.shadowRoot?.querySelector('#my-account')?.addEventListener('click', () => location.href = 'https://www.cartoonstock.com')
            
        // Logged in event listener
        window.eventBus.addEventListener('logged-in', () => this.loggedIn)

        this.shadowRoot?.querySelector(`#${this.dataset.page}`)?.classList.add('selected')

        if (this.dataset.page === 'search') {
            const page = new URLSearchParams(location.search).get('type')
            if (page === 'store') this.shadowRoot?.querySelector('#store')?.classList.add('selected')
            else this.shadowRoot?.querySelector('#cartoons')?.classList.add('selected')
        }

        // Update cart number
        if (localStorage.getItem('cart')) {
            (this.shadowRoot?.querySelector('#cart-quantity') as HTMLElement).innerText = JSON.parse(localStorage.getItem('cart') || '[]').reduce((acc:any, val:any) => acc + val.quantity, 0);
            (this.shadowRoot?.querySelector('#cart-quantity-tablet') as HTMLElement).innerText = JSON.parse(localStorage.getItem('cart') || '[]').reduce((acc:any, val:any) => acc + val.quantity, 0)
        } else {
            (this.shadowRoot?.querySelector('#cart-quantity') as HTMLElement).innerText = '0';
            (this.shadowRoot?.querySelector('#cart-quantity-tablet') as HTMLElement).innerText = '0'
        }

        window.eventBus.addEventListener('cart-updated', (e:CustomEvent) => {
            (this.shadowRoot?.querySelector('#cart-quantity') as HTMLElement).innerText = JSON.parse(localStorage.getItem('cart') || '[]').reduce((acc:any, val:any) => acc + val.quantity, 0);
            (this.shadowRoot?.querySelector('#cart-quantity-tablet') as HTMLElement).innerText = JSON.parse(localStorage.getItem('cart') || '[]').reduce((acc:any, val:any) => acc + val.quantity, 0);
        })

        // Look for Microsoft login code in url (if redirected from social login)
        if (new URLSearchParams(window.location.search).get('code')) {
            axios.post('https://api2.cartoonstock.com/user/login/microsoft', {
                code: new URLSearchParams(window.location.search).get('code')
            }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    localStorage.setItem('cs_uuid', res.data.uuid)
                    this.loggedIn
                    localStorage.setItem('login-method', 'microsoft')
                }
            })
        }
    }

    get loggedIn() {
        this.logged_in = true
        window.eventBus.dispatchEvent('uuid-updated');
        (this.shadowRoot?.querySelector('#log-in-btn') as HTMLElement).style.display = 'none';
        (this.shadowRoot?.querySelector('#sign-up-btn') as HTMLElement).style.display = 'none';
        (this.shadowRoot?.querySelector('#my-account') as HTMLElement).style.display = 'block';
        return
    }

    get loggedOut() {
        this.logged_in = false;
        (this.shadowRoot?.querySelector('#my-account') as HTMLElement).style.display = 'none';
        (this.shadowRoot?.querySelector('#log-in-btn') as HTMLElement).style.display = 'block';
        (this.shadowRoot?.querySelector('#sign-up-btn') as HTMLElement).style.display = 'block';
        location.reload()
        return
    }

    get loginCheck() {
        return this.logged_in
    }
})
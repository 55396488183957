customElements.define('nav-mobile', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    background-color: #4d4d4d;
                    height: 55px;
                    border-top: 1px solid #373737;
                    z-index: 3;
                }

                #my-account {
                    display: none;
                }

                button, a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-family: var(--font-main);
                    border: none;
                    color: #fff;
                    font-weight: 400;
                    background-color: transparent;
                    font-size: 10px;
                    text-decoration: none;
                    line-height: 1;
                    outline: none;
                }

                a {
                    position: relative;
                    font-size: 10px;
                }

                img {
                    margin-bottom: 5px;
                    filter: invert(100%) sepia(8%) saturate(26%) hue-rotate(263deg) brightness(107%) contrast(100%);
                }

                #menu img {
                    padding-top: 3px;
                    margin-bottom: 7px;
                }

                span {
                    position: absolute;
                    top: -4px;
                    right: -8px;
                    background-color: #d43b3b;
                    font-weight: bold;
                    color: #fff;
                    padding: 2px 6px;
                    border-radius: 12px;
                }
            </style>

            <div id='container'>
                <!-- <a href='#'><img src="https://assets.cartoonstock.com/images/favorites.svg" alt='favorites'>Favorites</a> -->
                <a href='https://www.cartoonstock.com/cart'><img src="https://assets.cartoonstock.com/images/shopping-cart.svg" alt='cart'><span id='cart-quantity'></span>Cart</a>
                <button id='login'><img src="https://assets.cartoonstock.com/images/user.svg" alt='log in'>Log In</button>
                <button id='my-account'><img src="https://assets.cartoonstock.com/images/user.svg" alt='my account'>Account</button>
                <button id='menu'><img src="https://assets.cartoonstock.com/images/menu.svg" alt='open menu'>Menu</button>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        this.shadowRoot?.querySelector('#menu')?.addEventListener('click', () => location.href = 'https://www.cartoonstock.com')

        // Update cart number
        if (localStorage.getItem('cart')) (this.shadowRoot?.querySelector('#cart-quantity') as HTMLElement).innerText = JSON.parse(localStorage.getItem('cart') || '[]').reduce((acc:any, val:any) => acc + val.quantity, 0)
        else (this.shadowRoot?.querySelector('#cart-quantity') as HTMLElement).innerText = '0'

        window.eventBus.addEventListener('cart-updated', (e:CustomEvent) => (this.shadowRoot?.querySelector('#cart-quantity') as HTMLElement).innerText = JSON.parse(localStorage.getItem('cart') || '[]').reduce((acc:any, val:any) => acc + val.quantity, 0));

        // Open login modal
        this.shadowRoot?.querySelector('#login')?.addEventListener('click', () => location.href = 'https://www.cartoonstock.com')

        // Logged in event listener
        window.eventBus.addEventListener('logged-in', () => {
            this.loggedIn
        })

        // My account event listener
        this.shadowRoot?.querySelector('#my-account')?.addEventListener('click', () => location.href = 'https://www.cartoonstock.com')
    }

    get loggedIn() {
        (this.shadowRoot?.querySelector('#login') as HTMLElement).style.display = 'none';
        (this.shadowRoot?.querySelector('#my-account') as HTMLElement).style.display = 'flex';
        return
    }

    get loggedOut() {
        (this.shadowRoot?.querySelector('#my-account') as HTMLElement).style.display = 'none';
        (this.shadowRoot?.querySelector('#login') as HTMLElement).style.display = 'flex';
        return
    }
})
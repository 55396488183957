customElements.define('site-footer', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    display: grid;
                    background-color: #3d3d3d;
                    width: 100%;
                    color: #fff;
                    font-size: 15px;
                    grid-template-areas:
                        'nav-1 nav-1 social'
                        'divider divider divider'
                        'copyright nav-2 nav-2';
                }

                #container a {
                    text-decoration: none;
                    color: #fff;
                }

                #nav-1 {
                    grid-area: nav-1;
                    margin: 40px 0 40px 30px;
                }

                #nav-1 a {
                    margin: 0 40px 40px 0;
                }

                #nav-2 {
                    grid-area: nav-2;
                    margin: 30px;
                    justify-self: end;
                }

                #nav-2 a {
                    margin-left: 40px;
                }

                #social {
                    grid-area: social;
                    justify-self: end;
                    margin: 40px 30px 40px 0;
                }

                #social img {
                    margin-left: 33px;
                }

                #copyright {
                    grid-area: copyright;
                    font-size: 14px;
                    margin: 30px;
                }

                .divider {
                    grid-area: divider;
                    border-top: 1px solid #979797;
                    margin: 0 30px;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {
                    #nav-1 a {
                        margin-right: 30px;
                    }

                    #nav-2 a {
                        margin-left: 30px;
                    }
                }

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {
                    #container {
                        grid-template-areas:
                            'nav-1 nav-2 . social'
                            'divider divider divider divider'
                            'copyright copyright . .';
                    }

                    #nav-1, #nav-2 {
                        display: flex;
                        flex-direction: column;
                        margin: 40px 40px 40px 0;
                        justify-self: start;
                    }

                    #nav-1 a, #nav-2 a {
                        margin: 0 0 16px 30px;
                    }

                    #social {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 0.5fr 1fr;
                    }
                }

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #container {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 80px
                    }

                    #social {
                        display: block;
                        margin: 40px 0 30px 20px;
                    }

                    #social img {
                        margin: 0 30px 0 0;
                    }

                    #nav-1 {
                        margin: 20px;
                    }

                    #nav-1 a {
                        margin: 20px 0;
                    }

                    #copyright {
                        margin: 0 0 40px 20px;
                    }

                    #nav-2 {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr 1fr;
                    }

                    #nav-2 a {
                        margin: 0 30px 20px 20px;
                    }

                    .divider {
                        margin: 0 20px;
                    }
                }
            </style>

            <div id='container'>
                <section id='nav-1'>
                    <a href='https://www.cartoonstock.com/about'>About Us</a>
                    <a href='https://www.hireanartist.net/'>Hire An Artist</a>
                    <a href='https://www.cartoonstock.com/license-agreement'>License Agreement</a>
                    <a href='https://www.cartoonstock.com/contact'>Help</a>
                </section>

                <div class='divider'></div>

                <section id='social'>
                    <a href='https://www.facebook.com/CartoonStockLtd'><img src='https://assets.cartoonstock.com/images/facebook.svg' alt='facebook social icon'></a>
                    <a href='https://twitter.com/CartoonStock'><img src='https://assets.cartoonstock.com/images/twitter.svg'  alt='twitter social icon'></a>
                    <a href='https://www.instagram.com/cartoonstockltd/'><img src='https://assets.cartoonstock.com/images/instagram.svg' alt='instagram social icon'></a>
                    <a href='https://www.linkedin.com/company/cartoonstock-ltd'><img src='https://assets.cartoonstock.com/images/linkedin.svg' alt='linkedin social icon'></a>
                </section>

                <section id='copyright'>
                    © 2020 CartoonStock Ltd. All Rights Reserved
                </section>

                <div class='divider'></div>

                <section id='nav-2'>
                    <a href='https://www.cartoonstock.com/terms'>Terms & Conditions</a>
                    <a href='https://www.cartoonstock.com/content-policy'>Content Policy</a>
                    <a href='https://www.cartoonstock.com/privacy'>Privacy Policy</a>
                    <a href='https://www.cartoonstock.com/directory-index'>Directory</a>
                </section>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }
})
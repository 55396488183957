import axios from '../node_modules/axios/index'

customElements.define('collection-container', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                * {
                    margin: 0;
                }

                #container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: var(--font-main);
                    color: #3d3d3d;
                    box-shadow: 3px 4px 11px 0 #0000000a;
                    border: solid 1px #e7e7e7;
                    background-color: ${this.dataset.backgroundColor};
                }

                #header {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    margin: 50px 0 5px 0;
                }

                #subheader {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    color: #808080;
                    text-align: center;
                    margin-bottom: 40px;
                }

                #collection-cards {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: flex-start;
                    max-width: 1000px;
                    margin-bottom: 40px;
                }

                .collection-card {
                    width: 270px;
                    height: auto;
                    margin: 15px;
                    border-radius: 12px;
                    box-shadow: 3px 4px 4px 0 #00000019;
                    border: solid 1px #e9e9e9;
                    background-color: #fff;
                }

                .collection-card:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                .image {
                    display: flex;
                    margin: 0 auto;
                    padding: ${this.dataset.collection?.includes('publication') ? '0' : '6px'};
                    width: ${this.dataset.collection?.includes('publication') ? 'auto' : '166px'};
                    height: ${this.dataset.collection?.includes('publication') ? 'auto' : '166px'};
                }

                .collection-card img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                }

                .divider {
                    height: 1px;
                    background-color: #e9e9e9;
                    width: 100%;
                }

                .collection-text {
                    padding: 0 20px;
                }

                .collection-type {
                    margin: 10px 0;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    color: var(--cs-orange);
                }

                .collection-name {
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 0.3px;
                    color: #3d3d3d;
                    margin-bottom: 8px;
                }

                .collection-description {
                    font-size: 14px;
                    line-height: 1.43;
                    letter-spacing: 0.23px;
                    color: #808080;
                    margin-bottom: 30px;
                }

                a {
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.23px;
                    text-decoration: none;
                    color: var(--link-blue);
                    margin-bottom: 50px;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #container {
                        border: none;
                        border-top: solid 1px #e7e7e7;
                        border-bottom: solid 1px #e7e7e7;
                    }

                    #header {
                        font-size: 13px;
                    }

                    #subheader {
                        font-size: 11px;
                        padding: 0 20px;
                    }

                    a {
                        font-size: 12px;
                    }

                    #collection-cards {
                        flex-direction: column;
                        flex-wrap: nowrap;
                    }

                    .collection-card {
                        width: auto;
                        height: auto;
                    }

                    .collection-card img {
                        width: 100%;
                    }
                }
            </style>

            <div id='container'>
                <h1 id='header'>Curated collections</h1>
                <p id='subheader'>Find inspiration within our carefully curated collections</p>

                <section id='collection-cards'></section>

                <a href=''>See all curated collections</a>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        this.renderContent((this.dataset.collection as string))
    }

    renderContent(collectionName:string) {
        if (!collectionName) return
        axios.get(`https://api2.cartoonstock.com/${this.dataset.collection}`).then(res => {
            const collection = res.data;
    
            (this.shadowRoot?.querySelector('#header') as HTMLElement).innerText = collection.header;
            (this.shadowRoot?.querySelector('#subheader') as HTMLElement).innerText = collection.subheader;

            if (this.dataset.collection === 'features/occasions') {
                (this.shadowRoot?.querySelector('a') as HTMLAnchorElement).style.display = 'none'
            } else if (this.dataset.collection === 'features/gift-ideas') {
                (this.shadowRoot?.querySelector('a') as HTMLAnchorElement).href = 'https://www.cartoonstock.com/gift-ideas';
                (this.shadowRoot?.querySelector('a') as HTMLAnchorElement).innerText = collection.link_text;
            } else {
                (this.shadowRoot?.querySelector('a') as HTMLAnchorElement).href = 'https://www.cartoonstock.com' + collection.url;
                (this.shadowRoot?.querySelector('a') as HTMLAnchorElement).innerText = collection.link_text;
            }
    
            let html = ''
            collection.cards.forEach((card:any) => {
                html += /*html*/ `
                    <div class='collection-card' data-url='https://www.cartoonstock.com${card.collection_url}'>
                        <div class='image'><img src='${card.image_url || 'https://via.placeholder.com/270x166.png'}' alt=''></div>
                        <div class='divider'></div>
                        <div class='collection-text'>
                            <p class='collection-type'>${card.type}</p>
                            <p class='collection-name'>${card.title}</p>
                            <p class='collection-description'>${card.description}</p>
                        </div>
                    </div>
                `
            });
            (this.shadowRoot?.querySelector('#collection-cards') as HTMLElement).innerHTML = html
            this.shadowRoot?.querySelectorAll('.collection-card').forEach((card:any) => card.addEventListener('click', () => location.href = card.dataset.url))
        })
    }
})
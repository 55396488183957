customElements.define('search-options', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                :host {
                    position: relative;
                }

                #dropdown-button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    font-family: var(--font-main);
                    border: solid 1px #979797;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    width: 162px;
                    font-size: 16px;
                    background-color: #fff;
                    outline: none;
                    padding: 15px 25px;
                }

                #dropdown-button img {
                    position: absolute;
                    right: 10px;
                    transition: transform 0.1s linear;
                    margin-top: -2px;
                }

                #dropdown {
                    font-family: var(--font-main);
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    background-color: #fff;
                    overflow: hidden;
                    transition: all 0.2s ease-in-out;
                    width: 160px;
                    height: 0;
                    margin-top: -1px;
                    font-size: 14px;
                    text-align: start;
                    border: 1px solid #979797;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    box-shadow: 3px 5px 11px 0 #00000020;
                    z-index: 1;
                }

                #dropdown div {
                    display: flex;
                    align-items: center;
                }

                #dropdown img {
                    display: none;
                }

                #dropdown div:first-child {
                    padding-top: 20px;
                }

                #dropdown span {
                    padding: 0 0 12px 30px;
                    text-decoration: none;
                    color: #3d3d3d;
                    font-size: 16px;
                }

                #dropdown div:last-child {
                    padding-bottom: 20px;
                }

                #dropdown span.header {
                    padding-left: 20px;
                    font-weight: bold;
                }

                #dropdown span:hover {
                    cursor: pointer;
                    color: var(--cs-orange);
                }

                .selected span {
                    color: var(--cs-orange) !important;
                }

                .divider {
                    display: none;
                    height: 1px;
                    background-color: #979797;
                    width: 94%;
                    margin: 0 auto;
                    transform: translateY(-1px);
                }

                .divider-inner {
                    display: none;
                }

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #dropdown-button {
                        display: none;
                        justify-content: space-between;
                        width: 100%;
                        border-top: none;
                        border-right: 1px solid #979797;
                        border-bottom: 1px solid #979797;
                        border-left: 1px solid #979797;
                        border-top-left-radius: 0px;
                        border-bottom-right-radius: 5px;
                        margin-top: -1px;
                    }

                    #dropdown {
                        width: calc(100vw - 41px);
                        border-top: none;
                        margin-top: -4px;
                        z-index: 10;
                    }

                    #dropdown div {
                        display: flex;
                        /* align-items: center; */
                    }

                    #dropdown img {
                        display: block;
                        visibility: hidden;
                    }

                    #dropdown div.selected img {
                        visibility: visible;
                    }

                    #dropdown span {
                        padding: 0 0 0 30px;
                    }

                    #dropdown div {
                        margin: 0 0 6px 10px;
                    }

                    #dropdown-button img {
                        position: relative;
                        right: 0;
                    }

                    .divider {
                        display: none;
                    }

                    .divider-inner {
                        display: block;
                        position: absolute;
                        height: 0;
                        left: 3%;
                        z-index: 1;
                        background-color: #979797;
                        width: 94%;
                        margin: 0 auto;
                    }
                }
            </style>

            <div class='divider'></div>
            <button id='dropdown-button' type='button'><span></span> <img id='nav-svg' src='https://assets.cartoonstock.com/images/chevron-down.svg' alt='down chevron'></button>
            <div class='divider-inner'></div>
            <div id='dropdown' data-open='false'></div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        const dropdown = (this.shadowRoot?.querySelector('#dropdown') as HTMLElement);

        // Populate search dropdown menu
        (this.shadowRoot?.querySelector('#dropdown-button span') as HTMLElement).innerText = JSON.parse((this.dataset.options as any))[0].name
        let html = ''
        JSON.parse((this.dataset.options as any)).forEach((item:any, i:number) => {
            html += /*html*/ `
                <div id='${item.value}' class=${i === 0 ? 'selected' : ''}>
                    <!-- <img src="https://assets.cartoonstock.com/images/check.svg" alt="check mark"> --><span class=${item.header ? 'header' : ''}>${item.name}</span>
                </div>
            `
        });
        (this.shadowRoot?.querySelector('#dropdown') as any).innerHTML = html

        // Select option based on search
        if (location.href.includes('recipient_for_her')) (this.shadowRoot?.querySelector('#dropdown-button span') as HTMLElement).innerText = 'Gifts for her'
        else if (location.href.includes('recipient_for_him')) (this.shadowRoot?.querySelector('#dropdown-button span') as HTMLElement).innerText = 'Gifts for him'
        else if (location.href.includes('store')) (this.shadowRoot?.querySelector('#dropdown-button span') as HTMLElement).innerText = 'All Products'
        else (this.shadowRoot?.querySelector('#dropdown-button span') as HTMLElement).innerText = 'All Images'

        // Select option and close dropdown
        this.shadowRoot?.querySelectorAll('#dropdown div').forEach((el:any) => {
            el.addEventListener('click', () => {
                (this.shadowRoot?.querySelector('#dropdown div.selected') as HTMLElement).classList.remove('selected')
                el.classList.add('selected');
                (this.shadowRoot?.querySelector('#dropdown-button span') as HTMLElement).innerText = el.innerText;
                dropdown.style.height = '0'
                setTimeout(() => {
                    if (window.innerWidth < 767) {
                        (this.shadowRoot?.querySelector('.divider-inner') as HTMLElement).style.height = '0'
                    }
                    dropdown.style.display = 'none';
                }, 200);
                (this.shadowRoot?.querySelector('#dropdown-button img') as HTMLElement).style.transform = 'rotate(0deg)';
                (this.shadowRoot?.querySelector('#dropdown-button') as HTMLElement).style.borderBottomLeftRadius = '5px';
                window.eventBus.dispatchEvent('search-dropdown', { value: el.id })
                dropdown.dataset.open = 'false'
            })
        })

        // Dropdown menu open and close
        this.shadowRoot?.querySelector('#dropdown-button')?.addEventListener('click', (e:any) => {
            if (dropdown.dataset.open === 'false') {
                // Dropdown opened
                e.target.style.borderBottomLeftRadius = '0px';
                (this.shadowRoot?.querySelector('#dropdown-button img') as HTMLElement).style.transform = 'rotate(180deg)'
                dropdown.style.display = 'flex'
                // dropdown.style.height = `${dropdown.scrollHeight}px`
                dropdown.style.height = '140px' // Safari was not getting scrollHeight
                if (window.innerWidth < 767) {
                    (this.shadowRoot?.querySelector('.divider-inner') as HTMLElement).style.height = '1px'
                }
                dropdown.dataset.open = 'true'
            } else {
                // Dropdown closed
                dropdown.style.height = '0'
                setTimeout(() => {
                    if (window.innerWidth < 767) {
                        (this.shadowRoot?.querySelector('.divider-inner') as HTMLElement).style.height = '0'
                    }
                    dropdown.style.display = 'none';
                }, 200);
                (this.shadowRoot?.querySelector('#dropdown-button img') as HTMLElement).style.transform = 'rotate(0deg)'
                e.target.style.borderBottomLeftRadius = '5px';
                dropdown.dataset.open = 'false'
            }
        })
    }

    get close() {
        const dropdown = (this.shadowRoot?.querySelector('#dropdown') as HTMLElement)
        dropdown.style.height = '0'
        setTimeout(() => {
            if (window.innerWidth < 767) {
                (this.shadowRoot?.querySelector('.divider-inner') as HTMLElement).style.height = '0'
            }
            dropdown.style.display = 'none';
        }, 200);
        (this.shadowRoot?.querySelector('#dropdown-button img') as HTMLElement).style.transform = 'rotate(0deg)';
        (this.shadowRoot?.querySelector('#dropdown-button') as HTMLElement).style.borderBottomLeftRadius = '5px';
        dropdown.dataset.open = 'false'
        return
    }
})
customElements.define('commercial-homepage', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 3px 4px 11px 0 #0000000a;
                    border: solid 1px #e7e7e7;
                    background-color: #efefef;
                    color: #3d3d3d;
                }

                #header {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    margin: 50px 0 5px 0;
                }

                #subheader {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    color: #808080;
                    margin-bottom: 30px;
                }

                #tiles {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    max-width: 964px;
                }

                .tile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 50px;
                }

                img {
                    height: 80px;
                    margin-right: 30px;
                }

                .tile-header {
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0.33px;
                    text-align: center;
                }

                .tile-subheader {
                    width: 250px;
                    font-size: 15px;
                    letter-spacing: 0.25px;
                    text-align: center;
                    color: #808080;
                }

                #help-text {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    text-align: center;
                    color: #808080;
                    margin-bottom: 14px;
                }

                a {
                    font-family: var(--font-main);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    height: 45px;
                    width: 400px;
                    border-radius: 5px;
                    box-shadow: 3px 4px 10px 0 #00000033;
                    background-color: var(--cs-orange);
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 0.25px;
                    color: #fff;
                    outline: none;
                    margin: 0 auto 50px auto;
                }

                a:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #header {
                        font-size: 13px;
                    }

                    #subheader {
                        font-size: 11px;
                        padding: 0 20px;
                    }

                    img {
                        height: 60px;
                        margin: 0;
                    }

                    .tile {
                        flex-direction: column;
                        margin: 20px;
                    }

                    .tile-header {
                        font-size: 15px;
                    }

                    .tile-subheader {
                        font-size: 12px;
                        line-height: 18px;
                    }

                    a {
                        width: calc(100% - 40px);
                        margin-bottom: 40px;
                    }
                }
            </style>

            <div id='container'>
                <h1 id='header'>Using cartoons for your business?</h1>
                <p id='subheader'>Made to measure solutions available</p>

                <div id='tiles'>
                    <div class='tile'>
                        <img src='https://assets.cartoonstock.com/images/commercial_promo_icons/tailored.png' alt='tailored icon'>
                        <div>
                            <p class='tile-header'>Tailored licenses</p>
                            <p class='tile-subheader'>Custom license agreements, tailored to your needs</p>
                        </div>
                    </div>

                    <div class='tile'>
                        <img src='https://assets.cartoonstock.com/images/commercial_promo_icons/invoice.png' alt='invoice icon'>
                        <div>
                            <p class='tile-header'>Invoice terms</p>
                            <p class='tile-subheader'>Invoice terms available upon request</p>
                        </div>
                    </div>

                    <div class='tile'>
                        <img src='https://assets.cartoonstock.com/images/commercial_promo_icons/access.png' alt='access icon'>
                        <div>
                            <p class='tile-header'>Commercial access</p>
                            <p class='tile-subheader'>Login and download cartoons on invoice terms</p>
                        </div>
                    </div>

                    <div class='tile'>
                        <img src='https://assets.cartoonstock.com/images/commercial_promo_icons/bespoke.png' alt='bespoke icon'>
                        <div>
                            <p class='tile-header'>Bespoke services</p>
                            <p class='tile-subheader'>Expert image research, cartoonist events, & much more</p>
                        </div>
                    </div>
                </div>

                <p id='help-text'>Find out how we can help your next project</p>
                <a href='https://www.cartoonstock.com/contact'>Get In Touch</a>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }
})
import axios from '../node_modules/axios/index'

customElements.define('contact-us', class extends HTMLElement {
    type = 'standard'

    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #3d3d3d;
                    /* background-color: #fafafa; */
                }

                input, textarea {
                    outline: none;
                }

                #header {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    margin: 50px 0 5px 0;
                }

                #subheader {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    text-align: center;
                    color: #808080;
                    margin-bottom: 40px;
                }

                #panel,
                #thanks {
                    width: 962px;
                    border-radius: 20px;
                    box-shadow: 3px 4px 11px 0 #00000019;
                    border: solid 1px #e7e7e7;
                    background-color: #fff;
                }
                
                #panel {
                    margin-bottom: 100px;
                }

                #thanks {
                    margin-bottom: 200px;
                }

                #banner,
                #banner-thanks {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 80px;
                    background-color: #3d3d3d;
                    border-top-right-radius: 20px;
                    border-top-left-radius: 20px;
                }

                #banner p,
                #banner-thanks p {
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 0.3px;
                    color: #fff;
                }

                form {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                #text-inputs {
                    display: flex;
                    justify-content: space-evenly;
                    padding: 40px 0 50px 0;
                    margin-bottom: 50px;
                    background-color: #f5f5f5;
                }

                .text-input {
                    display: flex;
                    flex-direction: column;
                }
                
                .text-input label {
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.23px;
                    margin-bottom: 10px;
                }
                
                .text-input input {
                    font-family: var(--font-main);
                    width: 328px;
                    height: 45px;
                    border-radius: 5px;
                    border: solid 1px #979797;
                    background-color: #fff;
                    padding: 0 12px;
                    font-size: 16px;
                    color: #3d3d3d;
                }

                h2 {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    text-align: center;
                }

                textarea {
                    font-family: var(--font-main);
                    width: 720px;
                    height: 156px;
                    border-radius: 5px;
                    border: solid 1px #979797;
                    background-color: #fff;
                    padding: 12px;
                    font-size: 16px;
                    color: #3d3d3d;
                    resize: none;
                    margin: 20px auto 40px auto;
                }
                
                button:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                #send {
                    font-family: var(--font-main);
                    height: 45px;
                    width: 400px;
                    border-radius: 5px;
                    box-shadow: 3px 4px 10px 0 #00000033;
                    background-color: var(--cs-orange);
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 0.25px;
                    text-align: center;
                    color: #fff;
                    border: none;
                    outline: none;
                    margin: 0 auto 50px auto;
                }

                #send:disabled {
                    border: solid 1px #ccc;
                    background-color: #ccc;
                }

                #send[disabled]:hover {
                    cursor: not-allowed;
                }

                #thanks {
                    display: none;
                    margin-top: 130px;
                }

                #thanks-subheader {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    text-align: center;
                    margin: 60px 0 15px 0;
                }

                #thanks-text {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    text-align: center;
                    color: #808080;
                    margin-bottom: 60px;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #panel, #thanks, #contact-information, #information {
                        width: 100%;
                    }

                    textarea, #send {
                        width: 80%;
                    }

                    #text-inputs {
                        flex-direction: column;
                        align-items: center;
                    }

                    #contact-cartoon {
                        display: none;
                    }

                    #header {
                        font-size: 14px;
                        margin: 20px 0 5px 0;
                    }

                    #subheader {
                        font-size: 12px;
                        padding: 0 20px;
                        margin-bottom: 20px;
                    }

                    #help-text {
                        margin: 24px 0 30px 0;
                        padding: 0 20px;
                        text-align: center;
                    }

                    #buttons button {
                        font-size: 10px;
                        margin: 0 8px;
                        border-bottom: 6px solid transparent;
                    }

                    #buttons button.selected {
                        border-bottom: 6px solid var(--cs-orange);
                    }

                    #help-text {
                        font-size: 12px;
                    }

                    #panel {
                        margin-bottom: 80px;
                        border-radius: 0;
                    }

                    #thanks {
                        margin-bottom: 80px;
                    }

                    #banner,
                    #banner-thanks {
                        height: 50px;
                        border-radius: 0;
                    }

                    #banner p,
                    #banner-thanks p {
                        font-size: 13px;
                    }

                    #text-inputs {
                        margin: 0 0 26px 0;
                    }

                    .text-input label {
                        font-size: 12px;
                        text-align: center;
                    }

                    #name {
                        margin-bottom: 26px;
                    }

                    h2 {
                        font-size: 13px;
                    }

                    textarea {
                        height: 174px;
                        padding: 8px;
                        font-size: 12px;
                    }

                    #send {
                        font-size: 15px;
                    }

                    #thanks {
                        margin-top: 30px;
                        border-radius: 0;
                    }

                    #thanks-subheader {
                        font-size: 13px;
                        margin: 26px 0 14px 0;
                    }

                    #thanks-text {
                        font-size: 10px;
                        margin-bottom: 26px;
                    }
                }
            </style>

            <div id='container'>
                <h1 id='header'>Need help?</h1>
                <p id='subheader'>Send us a message and we’ll get back to you as soon as possible.</p>

                <div id='panel'>
                    <div id='banner'><p>Get in touch</p></div>

                    <form>
                        <div id='text-inputs'>
                            <div class='text-input'>
                                <label for='name'>Name</label>
                                <input id='name' type='text' required>
                            </div>
                            <div class='text-input'>
                                <label for='email'>Email address</label>
                                <input id='email' type='email' required>
                            </div>
                        </div>

                        <h2>Write your message</h2>
                        <textarea required></textarea>
                        <button type='submit' id='send'>Send</button>
                    </form>
                </div>

                <div id='thanks'>
                    <div id='banner-thanks'><p>Thanks!</p></div>
                    <p id='thanks-subheader'>Your message has been successfully sent</p>
                    <p id='thanks-text'>One of our team members will get back to you as soon as they can</p>
                </div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        // Form submit event listener
        this.shadowRoot?.querySelector('form')?.addEventListener('submit', e => this.send(e))
    }

    // Send form data
    send(e:Event) {
        e.preventDefault()

        axios.post('https://api2.cartoonstock.com/contact', {
            category: 'cartoon_collections',
            sub_category: 'ghost_site',
            name: (this.shadowRoot?.querySelector('#name') as HTMLInputElement).value,
            email: (this.shadowRoot?.querySelector('#email') as HTMLInputElement).value,
            message: this.shadowRoot?.querySelector('textarea')?.value
        }).then(res => {
            if (res.data.success) {
                (this.shadowRoot?.querySelector('#help-text') as HTMLElement).style.display = 'none';
                (this.shadowRoot?.querySelector('#panel') as HTMLElement).style.display = 'none';
                (this.shadowRoot?.querySelector('#thanks') as HTMLElement).style.display = 'block';
            }
        })
    }
})
import '../components/nav-dropdown-gallery'

customElements.define('nav-secondary', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    border-bottom: solid 1px #ebebeb;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: ${this.dataset.backgroundColor};
                    height: 44px;
                    padding: 0 15px;
                }
                
                #nav-left, #nav-right {
                    display: flex;
                }

                button, a {
                    display: flex;
                    align-items: center;
                    font-family: var(--font-main);
                    border: none;
                    padding: 0;
                    margin: 0 15px;
                    color: #3d3d3d;
                    font-weight: 400;
                    background-color: transparent;
                    font-size: 100%;
                    text-decoration: none;
                }

                .nav-menu {
                    display: flex;
                    flex-direction: column;
                }

                #collections-menu:hover #collections-btn {
                    font-weight: 500;
                }

                #collections-menu:hover #collections-dropdown {
                    height: 370px;
                }

                #topical-btn:hover,
                #curated-btn:hover,
                #collections-btn:hover {
                    cursor: pointer;
                }

                #collections-dropdown {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    background-color: #fff;
                    overflow: hidden;
                    transition: all 0.5s ease-in-out;
                    height: 0;
                    margin-top: 11px;
                }

                #collection-cards {
                    display: flex;
                    align-items: center;
                    margin: 40px 0;
                }

                #collection-cards .svg {
                    height: 200px;
                    width: 200px;
                    margin: 0 30px;
                }

                .gallery {
                    display: flex;
                    overflow-x: scroll;
                    scroll-snap-type: x mandatory;
                }

                .collection-card {
                    margin: 0 30px;
                    scroll-snap-align: center;
                }

                .view-more {
                    display: flex;
                    justify-content: center;
                    color: var(--link-blue);
                    text-align: center;
                    height: 60px;
                    line-height: 60px;
                    border-top: 1px solid #efefef;
                    margin: 0 30px;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {
                    #nav-right {
                        display: none;
                    }
                }

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {}
            </style>

            <div id='container'>
                <div id='nav-left'></div>
                <div id='nav-right'></div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        if (this.dataset.page === 'search') {
            const page = new URLSearchParams(location.search).get('type')
            if (page === 'store') (this.shadowRoot?.querySelector('#container') as HTMLElement).style.backgroundColor = '#d4e3e4'
        }

        let html = ''
        JSON.parse((this.dataset.menuLeft as any)).forEach((button:any) => {
            if (button.type == 'dropdown-gallery') {
                html += /*html*/ `
                    <nav-dropdown-gallery data-name='${button.name}'></nav-dropdown-gallery>
                `
            } else if (button.type == 'link') {
                html += /*html*/ `
                    <a href='${button.url}'>${button.name}</a>
                `
            }
        })
        ;(this.shadowRoot?.querySelector('#nav-left') as HTMLElement).innerHTML = html

        html = ''
        JSON.parse((this.dataset.menuRight as any)).forEach((button:any) => {
            if (button.type == 'dropdown') {
                html += /*html*/ `
                    <nav-dropdown-gallery data-name='${button.name}'></nav-dropdown-gallery>
                `
            } else if (button.type == 'link') {
                html += /*html*/ `
                    <a href='${button.url}'>${button.name}</a>
                `
            }
        });
        (this.shadowRoot?.querySelector('#nav-right') as HTMLElement).innerHTML = html
    }
})
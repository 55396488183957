import axios from '../node_modules/axios/index'

customElements.define('suggested-tags', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin: 30px 0 40px 0;
                }

                .tag {
                    visibility: hidden;
                    font-size: 14px;
                    border: 1px solid #afafaf;
                    padding: 6px 18px;
                    border-radius: 15px;
                    color: #3d3d3d;
                    background-color: #efefef;
                    margin: 7px;
                }

                .tag:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #container {
                        margin: 20px 0 16px 0;
                    }

                    .tag {
                        font-size: 10px;
                        padding: 6px 8px;
                        margin: 6px;
                    }
                }
            </style>

            <div id='container'>
                <div class='tag'></div>
                <div class='tag'></div>
                <div class='tag'></div>
                <div class='tag'></div>
                <div class='tag'></div>
                <div class='tag'></div>
                <div class='tag'></div>
                <div class='tag'></div>
                <div class='tag'></div>
                <div class='tag'></div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        window.eventBus.addEventListener('search-query', (e:CustomEvent) => {
            const params = JSON.parse(window.localStorage.getItem('filters') as string)
            axios.post('https://api2.cartoonstock.com/search/search-keywords', {
                keyword: e.detail.keyword,
                ...params
            }, { withCredentials: true }).then(res => {
                let html = ''
                res.data.forEach((result:any) => {
                    html += /*html*/ `
                        <div class='tag'>${result.keyword}</div>
                    `
                });
                (this.shadowRoot?.querySelector('#container') as HTMLElement).innerHTML = html
                this.shadowRoot?.querySelectorAll('.tag').forEach((tag:any) => tag.style.visibility = 'visible')

                this.shadowRoot?.querySelectorAll('.tag').forEach((tag:any) => {
                    tag.addEventListener('click', () => {
                        window.eventBus.dispatchEvent('search', { keyword: tag.innerText })
                    })
                })
            })
        })
    }
})
customElements.define('cartoonstock-promo', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 3px 4px 11px 0 #0000000a;
                    border: solid 1px #e7e7e7;
                    background-color: #efefef;
                    color: #3d3d3d;
                }

                #header {
                    font-family: var(--font-serif);
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    margin: 50px 0 10px 0;
                }

                #tiles {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    max-width: 964px;
                }

                .tile {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 50px;
                }

                img {
                    height: 40px;
                    margin-bottom: 20px;
                }

                .tile-text {
                    font-size: 14px;
                    letter-spacing: 0.33px;
                    text-align: center;
                    width: 300px;
                }

                #help-text {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    text-align: center;
                    color: #808080;
                    margin-bottom: 14px;
                }

                a {
                    font-family: var(--font-main);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    font-size: 15px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.25px;
                    text-align: center;
                    color: var(--link-blue);
                    margin-bottom: 55px;
                }

                a:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #header {
                        font-size: 15px;
                        margin: 30px 0 10px 0;
                    }

                    img {
                        height: 30px;
                        margin: 0;
                    }

                    .tile {
                        flex-direction: column;
                        margin: 20px;
                    }

                    .tile-text {
                        font-size: 12px;
                    }

                    a {
                        width: calc(100% - 40px);
                        font-size: 11px;
                        margin-bottom: 40px;
                    }
                }
            </style>

            <div id='container'>
                <h1 id='header'>Same great cartoons, new & improved features</h1>

                <div id='tiles'>
                    <div class='tile'>
                        <img src='https://assets.cartoonstock.com/images/tshirt.png' alt='tshirt icon'>
                        <p class='tile-text'>Find the perfect gift on the new store!<br>
                        Browse by recipient and occasion</p>
                    </div>

                    <div class='tile'>
                        <img src='https://assets.cartoonstock.com/images/history.png' alt='history icon'>
                        <p class='tile-text'>Access your previous downloads & order history</p>
                    </div>

                    <!-- <div class='tile'>
                        <img src='https://assets.cartoonstock.com/images/heart.png' alt='heart icon'>
                        <p class='tile-text'>View your existing lightboxes, and bookmark new favourite cartoons & products</p>
                    </div> -->

                    <div class='tile'>
                        <img src='https://assets.cartoonstock.com/images/save.png' alt='save icon'>
                        <p class='tile-text'>Securely save your payment details for speedy checkout</p>
                    </div>
                </div>

                <a href='https://www.cartoonstock.com'>Log in with your Cartoon Collections account</a>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }
})
import '../components/image-grid-mini'

customElements.define('cartoon-feed', class extends HTMLElement {
    mobile = window.innerWidth < 768

    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                * {
                    margin: 0;
                }

                #container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: var(--font-main);
                    color: #3d3d3d;
                    box-shadow: 3px 4px 11px 0 #0000000a;
                    border: solid 1px #e7e7e7;
                    background-color: ${this.dataset.backgroundColor};
                }

                #header {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    margin: 50px 0 5px 0;
                }

                #subheader {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    color: #808080;
                    text-align: center;
                    margin-bottom: 40px;
                }

                image-grid-mini {
                    width: 964px;
                    margin: 0 auto 40px auto;
                }

                button {
                    font-family: var(--font-main);
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.23px;
                    color: var(--link-blue);
                    margin-bottom: 50px;
                    background-color: transparent;
                    border: none;
                    outline: none;
                }

                button:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    image-grid-mini {
                        width: 100%;
                    }

                    #header {
                        font-size: 13px;
                    }

                    #subheader {
                        font-size: 11px;
                        padding: 0 20px;
                    }

                    button {
                        font-size: 12px;
                    }
                }
            </style>

            <div id='container'>
                <h1 id='header'>Topical cartoons newsfeed</h1>
                <p id='subheader'>A selection of the very latest topical cartoons uploaded to CartoonStock</p>

                <image-grid-mini data-url='${this.dataset.imagesUrl}'></image-grid-mini>

                <button>See more of the latest topical cartoons</button>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        this.shadowRoot?.querySelector('button')?.addEventListener('click', () => {
            (this.shadowRoot?.querySelector('button') as HTMLElement).remove();
            (this.shadowRoot?.querySelector('image-grid-mini') as any).regenGrid = { targetHeight: 300, rowLimit: 10 }
        })
    }
})
customElements.define('merchandise-homepage', class extends HTMLElement {
    mobile = window.innerWidth < 768

    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                * {
                    margin: 0;
                }

                #container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: var(--font-main);
                    color: #3d3d3d;
                    background-color: #fafafa;
                }

                #header {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.3px;
                    margin: 50px 0 5px 0;
                }

                #subheader {
                    font-size: 14px;
                    letter-spacing: 0.23px;
                    color: #808080;
                    margin-bottom: 40px;
                }

                #merchandise-banner {
                    display: flex;
                    border-radius: 12px;
                    box-shadow: 3px 4px 10px 0 #0000000a;
                    border: solid 1px #e7e7e7;
                    background-color: #ddedee;
                    margin-bottom: 40px;
                }

                #merchandise-banner img {
                    height: 230px;
                }

                #merchandise-details {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 0 40px;
                }

                #merchandise-banner-header {
                    font-family: var(--font-serif);
                    font-size: 36px;
                    letter-spacing: 0.6px;
                    text-align: center;
                    margin-bottom: 20px;
                }

                #merchandise-banner-subheader {
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.27px;
                    width: 300px;
                    text-align: center;
                    color: #808080;
                    margin-bottom: 40px;
                }

               #merchandise-banner a {
                    font-family: var(--font-main);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    height: 45px;
                    width: 260px;
                    border-radius: 5px;
                    box-shadow: 3px 4px 10px 0 #00000033;
                    background-color: var(--cs-orange);
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 0.25px;
                    color: #fff;
                    outline: none;
                }

                #merchandise-banner button:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

               #cards {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 964px;
                    margin-bottom: 50px;
                }

                .card {
                    width: 270px;
                    height: 320px;
                    border-radius: 12px;
                    box-shadow: 3px 4px 4px 0 #00000019;
                    border: solid 1px #e9e9e9;
                    background-color: #fff;
                }

                .card:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                .image {
                    display: flex;
                    margin: 0 auto;
                    height: 166px;
                }

                .card img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                }

                .divider {
                    height: 1px;
                    background-color: #e9e9e9;
                    width: 100%;
                }

                .card-text {
                    padding: 0 20px;
                }

                .card-type {
                    margin: 10px 0;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    color: var(--cs-orange);
                }

                .card-name {
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 0.3px;
                    color: #3d3d3d;
                    margin: 26px 0 8px 0;
                }

                .card-description {
                    font-size: 14px;
                    line-height: 1.43;
                    letter-spacing: 0.23px;
                    color: #808080;
                    margin-bottom: 30px;
                }
                
                a:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {}

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {}

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #header {
                        font-size: 13px;
                    }

                    #subheader {
                        font-size: 11px;
                        padding: 0 20px;
                    }

                    #merchandise-banner {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0;
                        width: 100%;
                    }

                    #merchandise-banner img {
                        width: calc(100% - 40px);
                        height: auto;
                        margin: 20px;
                    }

                    #merchandise-banner-header {
                        font-size: 24px;
                        margin-bottom: 12px;
                    }

                    #merchandise-banner-subheader {
                        font-size: 12px;
                        margin-bottom: 30px;
                    }

                    #merchandise-banner a {
                        margin-bottom: 20px;
                    }

                    #cards {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: calc(100% - 40px);
                        flex-direction: column;
                        flex-wrap: nowrap;
                    }

                    .card {
                        margin: 15px 0;
                        width: auto;
                        height: auto;
                    }

                    
                }
            </style>

            <div id='container'>
                <h1 id='header'>Not just cartoon licensing….</h1>
                <p id='subheader'>Explore the CartoonStock store, caption contest & more</p>
                
                <div id='merchandise-banner'>
                    <img src='${this.mobile ? 'https://assets.cartoonstock.com/images/products-mobile.png' : 'https://assets.cartoonstock.com/images/products.png'}' alt='merchandise graphic'>
                    <div id='merchandise-details'>
                        <p id='merchandise-banner-header'>Find the perfect gift</p>
                        <p id='merchandise-banner-subheader'>Browse cartoon gift ideas by occassion & recipient</p>
                        <a href='https://www.cartoonstockgifts.com?utm_source=cartooncollections&utm_medium=link&utm_campaign=cartooncollections-topnavlink'>Visit Our Store</a>
                    </div>
                </div>

                <div id='cards'>
                    <div class='card' data-url='https://more.cartooncollections.com/cartoon-caption-contest/'>
                        <div class='image'><img src='https://assets.cartoonstock.com/images/caption_contest/latest.jpg' alt='caption contest image'></div>
                        <div class='divider'></div>
                        <div class='card-text'>
                            <p class='card-name'>Caption contest</p>
                            <p class='card-description'>Ever thought you could write knee-slapping captions? Now’s your chance! Enter our caption contest.</p>
                        </div>
                    </div>
                    <div class='card' data-url='https://more.cartooncollections.com/cartoonathons/'>
                        <div class='image'><img src='https://assets.cartoonstock.com/images/cartoonathons.png' alt='cartoonathons image'></div>
                        <div class='divider'></div>
                        <div class='card-text'>
                            <p class='card-name'>Cartoonathons</p>
                            <p class='card-description'>Live, virtual and personalized events bringing New Yorker cartoonists directly to YOU.</p>
                        </div>
                    </div>
                    <div class='card' data-url='https://more.cartooncollections.com/'>
                        <div class='image'><img src='https://assets.cartoonstock.com/images/blog/screenshot.jpg' alt='blog image'></div>
                        <div class='divider'></div>
                        <div class='card-text'>
                            <p class='card-name'>More</p>
                            <p class='card-description'>News, interviews, caption contest commentary, licensing tips & more! Browse our blog.</p>
                        </div>
                    </div>
                </div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        // Cards click event listeners
        this.shadowRoot?.querySelectorAll('.card').forEach((card:any) => card.addEventListener('click', () => location.href = card.dataset.url))

        // Change graphic for mobile/desktop
        window.addEventListener('resize', () => {
            if (window.innerWidth < 768) (this.shadowRoot?.querySelector('#merchandise-banner img') as HTMLImageElement).src = 'https://assets.cartoonstock.com/images/products-mobile.png'
            else (this.shadowRoot?.querySelector('#merchandise-banner img') as HTMLImageElement).src = 'https://assets.cartoonstock.com/images/products.png'
        })
    }
})
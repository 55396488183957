import axios from '../node_modules/axios/index'

customElements.define('nav-dropdown-gallery', class extends HTMLElement {
    height = this.dataset.name === 'Collections' ? '306px' : '320px'

    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                * {
                    margin: 0;
                }

                #container {
                    font-family: var(--font-main);
                    color: #3d3d3d;
                    width: 100%;
                }

                button, a {
                    display: flex;
                    align-items: center;
                    font-family: var(--font-main);
                    border: none;
                    padding: 0;
                    margin: 0 15px;
                    color: #3d3d3d;
                    font-weight: 400;
                    background-color: transparent;
                    font-size: 100%;
                    text-decoration: none;
                }

                #container:hover button {
                    font-weight: 500;
                }
                
                #container:hover #dropdown {
                    height: ${this.height};
                }
                
                button:hover,
                .card:hover,
                #view-more:hover,
                .svg:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
                
                #dropdown {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    background-color: #fff;
                    overflow: hidden;
                    transition: all 0.5s ease-in-out;
                    height: 0;
                    margin-top: 11px;
                    box-shadow: 0 5px 17px 0 #00000019;
                    border-top: solid 1px #ebebeb;
                    border-bottom: solid 1px #ebebeb;
                    z-index: 10;
                }
                
                #nav-svg {
                    transition: transform 0.1s linear;
                    margin-left: 5px;
                }
                
                #container:hover #nav-svg {
                    transform: rotate(180deg);
                }

                #cards {
                    display: flex;
                    align-items: center;
                    margin: 40px 0 0 0;
                }

                #cards .svg {
                    height: 35px;
                    margin: 0 30px;
                    opacity: 0.3;
                }

                #gallery {
                    display: flex;
                    overflow-x: scroll;
                    width: 100%;
                    scroll-behavior: smooth;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }

                #gallery::-webkit-scrollbar {
                    display: none;
                }

                .card {
                    width: 210px;
                    margin: 0 20px;
                    scroll-snap-align: center;
                }

                .image {
                    display: flex;
                    margin: 0 auto;
                    width: 100%;
                    height: 112px;
                }

                .card img {
                    object-fit: cover;
                    width: 210px;
                    height: 100%;
                    margin: 0 auto;
                    border-radius: 12px;
                    box-shadow: 0 5px 17px 0 #00000019;
                    border: solid 1px #ebebeb;
                }

                .title {
                    font-size: 13px;
                    font-weight: 600;
                    letter-spacing: 0.22px;
                    margin: 12px 0 6px 4px;
                }

                .description {
                    font-size: 11px;
                    line-height: 1.36;
                    letter-spacing: 0.18px;
                    color: #808080;
                    margin: 0 0 20px 4px;
                }

                #view-more {
                    display: flex;
                    justify-content: center;
                    color: var(--link-blue);
                    text-align: center;
                    font-size: 14px;
                    border-top: 1px solid #efefef;
                    padding: 20px 0;
                }
            </style>

            <div id='container'>
                <button>${this.dataset.name} <img id='nav-svg' src='https://assets.cartoonstock.com/images/chevron-down.svg' alt='down chevron'></button>
                <div id='dropdown'>
                    <section id='cards'>
                        <img id='left' class='svg' src='https://assets.cartoonstock.com/images/chevron-left.svg' alt='nav left'>
                        <div id='gallery'></div>
                        <img id='right' class='svg' src='https://assets.cartoonstock.com/images/chevron-right.svg' alt='nav right'>
                    </section>
                    <a href='' id='view-more'>View all ${this.dataset.name}</a>
                </div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }
    
    connectedCallback() {
        if (this.dataset.name === 'Products') {
            // No need for api call for 'Products
            const products = {
                cards: [
                    {
                        collection_url: 'https://www.cartoonstock.com/search?type=store&store_product=print',
                        description: 'Bring our cartoons to your home or office - perfect for any room!',
                        image_url: 'https://assets.cartoonstock.com/images/homepage-store/prints.png',
                        title: 'Prints',
                        type: 'Product'
                    },
                    {
                        collection_url: 'https://www.cartoonstock.com/search?type=store&store_product=mug',
                        description: 'This sturdy mug is perfect for your morning coffee, afternoon tea, or whatever hot beverage you enjoy.',
                        image_url: 'https://assets.cartoonstock.com/images/homepage-store/mugs.png',
                        title: 'Mugs',
                        type: 'Product'
                    },
                    {
                        collection_url: 'https://www.cartoonstock.com/search?type=store&store_product=tshirt',
                        description: 'Unisex Short Sleeve T-Shirts.',
                        image_url: 'https://assets.cartoonstock.com/images/homepage-store/tshirts.png',
                        title: 'T-Shirts',
                        type: 'Product'
                    },
                    {
                        collection_url: 'https://www.cartoonstock.com/search?type=store&store_product=pillow',
                        description: 'These throw pillows are precision-cut and hand-sewn to serve as the perfect decorative accent in any environment.',
                        image_url: 'https://assets.cartoonstock.com/images/homepage-store/pillows.png',
                        title: 'Pillows',
                        type: 'Product'
                    }
                ]
            };

            (this.shadowRoot?.querySelector('#left') as HTMLElement).style.display = 'none';
            (this.shadowRoot?.querySelector('#right') as HTMLElement).style.display = 'none';
            (this.shadowRoot?.querySelector('a') as HTMLAnchorElement).style.display = 'none'

            let html = ''
            products.cards.forEach((card:any) => {
                html += /*html*/ `
                    <div class='card' data-url='${card.collection_url}'>
                        <div class='image'><img src='${card.image_url}' alt='gallery image'></div>
                        <p class='title'>${card.title}</p>
                        <p class='description'>${card.description}</p>
                    </div>
                `
            });
            (this.shadowRoot?.querySelector('#gallery') as HTMLElement).style.justifyContent = 'center';
            (this.shadowRoot?.querySelector('#gallery') as HTMLElement).innerHTML = html;
            this.shadowRoot?.querySelectorAll('.card').forEach((card:any) => card.addEventListener('click', () => location.href = card.dataset.url));

        } else {
            // Get data for gallery
            let url = ''
            if (this.dataset.name === 'Topical') url = 'https://api2.cartoonstock.com/features/curated-topical'
            else if (this.dataset.name === 'Curated') url = 'https://api2.cartoonstock.com/features/curated'
            else if (this.dataset.name === 'Collections') url = 'https://api2.cartoonstock.com/features/publications'
            else if (this.dataset.name === 'Occasions') url = 'https://api2.cartoonstock.com/features/occasions'
    
            axios.get(url).then(res => {
                const collection = res.data
    
                let html = ''
                collection.cards.forEach((card:any) => {
                    html += /*html*/ `
                        <div class='card' data-url='https://www.cartoonstock.com${card.collection_url}'>
                            <div class='image'><img src='${card.image_url}' alt='gallery image'></div>
                            <p class='title'>${card.title}</p>
                            <p class='description'>${card.description}</p>
                        </div>
                    `
                });
                (this.shadowRoot?.querySelector('#gallery') as HTMLElement).innerHTML = html;
                this.shadowRoot?.querySelectorAll('.card').forEach((card:any) => card.addEventListener('click', () => location.href = card.dataset.url));
    
                if (this.dataset.name === 'Occasions') (this.shadowRoot?.querySelector('a') as HTMLAnchorElement).style.display = 'none'
                else (this.shadowRoot?.querySelector('a') as HTMLAnchorElement).href = 'https://www.cartoonstock.com' + collection.url
            })
            
            // Nav scroll event listeners
            this.shadowRoot?.querySelectorAll('.svg').forEach((nav:any) => nav.addEventListener('click', () => this.navScroll(nav.id)))
        }

    }

    navScroll(id:string) {
        if (id === 'right') (this.shadowRoot?.querySelector('#gallery') as HTMLElement).scrollLeft += 250
        else (this.shadowRoot?.querySelector('#gallery') as HTMLElement).scrollLeft += -250
    }
})
customElements.define('menu-mobile-dropdown', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    width: 100%;
                }

                button {
                    font-family: var(--font-main);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 66px;
                    background-color: #3d3d3d;
                    width: 100%;
                    border: none;
                    color: #fff;
                    padding: 24px 30px 24px 20px;
                    font-size: 16px;
                    outline: none;
                    border-left: 10px solid transparent;
                }

                .selected {
                    border-left: 10px solid var(--cs-orange);
                }

                img {
                    transition: transform 0.1s linear;
                    filter: invert(100%) sepia(8%) saturate(26%) hue-rotate(263deg) brightness(107%) contrast(100%);
                }

                #dropdown {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 100%;
                    left: 0;
                    background-color: #e0e0e0;
                    overflow: hidden;
                    transition: all 0.5s ease-in-out;
                    height: 0px;
                }

                #dropdown a {
                    margin: 12px 60px;
                    color: #3d3d3d;
                    text-decoration: none;
                }

                #dropdown a:first-child {
                    margin-top: 24px;
                }

                #dropdown a:last-child {
                    margin-bottom: 24px;
                }

                /* Tablet only layout */
                @media only screen and (min-width: 768px) and (max-width: 1023px) {
                    button {
                        background-color: #fff;
                        color: #3d3d3d;
                        border-top: 1px solid #f1f1f1;
                        ${this.dataset.last ? 'border-bottom: 1px solid #f1f1f1;' : ''}
                    }

                    #dropdown {
                        background-color: #f1f1f1;
                    }

                    img {
                        filter: invert(22%) sepia(0%) saturate(1529%) hue-rotate(136deg) brightness(102%) contrast(96%);
                    }

                    .selected {
                        color: var(--cs-orange);
                    }
                }
            </style>

            <div id='container'>
                <button><span>${this.dataset.name}</span> <img id='nav-svg' src='https://assets.cartoonstock.com/images/chevron-down.svg' alt='down chevron'></button>
                <div id='dropdown'></div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        const dropdown = (this.shadowRoot?.querySelector('#dropdown') as HTMLElement)
        let html = ''
        JSON.parse((this.dataset.options as any)).forEach((option:any) => {
            html += /*html*/ `<a href='${option.url}'>${option.name}</a>`
        });
        dropdown.innerHTML = html

        this.shadowRoot?.querySelector('button')?.addEventListener('click', () => {
            if (dropdown.style.height == `${dropdown.scrollHeight}px`) {
                dropdown.style.height = '0px';
                (this.shadowRoot?.querySelector('button img') as HTMLElement).style.transform = 'rotate(0deg)'
                this.shadowRoot?.querySelector('button')?.classList.remove('selected')
            } else {
                this.parentNode?.querySelectorAll('menu-mobile-dropdown').forEach((el:any) => {
                    el.close
                });
                this.shadowRoot?.querySelector('button')?.classList.add('selected');
                (this.shadowRoot?.querySelector('button img') as HTMLElement).style.transform = 'rotate(180deg)'
                dropdown.style.height = `${dropdown.scrollHeight}px`
            }
        })
    }

    get open() {
        const dropdown = (this.shadowRoot?.querySelector('#dropdown') as HTMLElement)
        this.shadowRoot?.querySelector('button')?.classList.add('selected');
        (this.shadowRoot?.querySelector('button img') as HTMLElement).style.transform = 'rotate(180deg)'
        dropdown.style.height = `${dropdown.scrollHeight}px`
        return (document.querySelector('menu-mobile') as HTMLElement).dataset.open = 'true'
    }

    get close() {
        (this.shadowRoot?.querySelector('#dropdown') as HTMLElement).style.height = '0px';
        (this.shadowRoot?.querySelector('button img') as HTMLElement).style.transform = 'rotate(0deg)'
        return this.shadowRoot?.querySelector('button')?.classList.remove('selected')
    }
})
import axios from '../node_modules/axios/index'
import './search-desktop'

customElements.define('banner-search', class extends HTMLElement {
    mobile = window.innerWidth < 768

    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    /* height: 330px; */
                    background-color: ${this.dataset.backgroundColor};
                    padding: 0 20px;
                    color: #3d3d3d;
                }

                #text-content {
                    margin: auto 0;
                }

                h1 {
                    font-family: var(--font-serif);
                    margin: 0 0 10px 0;
                    font-size: 50px;
                    letter-spacing: 0.67px;
                    margin-top: 40px;
                }

                h2 {
                    font-family: var(--font-main);
                    margin: 0;
                    color: #666666;
                    font-weight: 200;
                    font-size: 28px;
                }

                h2 span {
                    font-weight: 200;
                    font-style: italic;
                }

                search-desktop {
                    margin-bottom: 74px;
                }

                #collection-tabs {
                    border-radius: 24px;
                    background-color: #eaeaea;
                    padding: 9px 0;
                    margin: 0;
                }

                .collection-logo {
                    width: 102px;
                    opacity: 0.7;
                    margin: 0 30px;
                }

                .collection-logo:hover {
                    cursor: pointer;
                    opacity: 1;
                }

                search-desktop {
                    margin-bottom: 60px;
                }

                /* Store specific */
                #store {
                    display: none;
                    margin: -30px 0 40px 0;
                }

                #store img {
                    height: 360px;
                    margin-right: -30px;
                }

                #header-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                #store-header {
                    font-family: var(--font-serif);
                    font-size: 44px;
                    letter-spacing: 0.73px;
                    text-align: center;
                    width: 370px;
                    margin-bottom: 80px;
                }

                form {
                    display: grid;
                    grid-template-columns: 1fr 1fr 0.1fr;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 2px 2px 10px 0 #00000028;
                    background-color: #cfe4e6;
                }

                form select {
                    font-family: var(--font-main);
                    height: 45px;
                    padding: 0 12px;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 5px;
                    border: solid 1px #979797;
                    background-color: #fff;
                    margin-right: 30px;
                    outline: none;
                }

                form button {
                    font-family: var(--font-main);
                    height: 45px;
                    width: 100px;
                    border-radius: 5px;
                    box-shadow: 3px 4px 10px 0 #00000033;
                    background-color: var(--cs-orange);
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 0.25px;
                    text-align: center;
                    color: #fff;
                    border: none;
                    outline: none;
                }

                form button:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                /* Desktop small layout */
                @media only screen and (max-width: 1439px) {
                    h1 {
                        font-size: 40px;
                    }

                    h2 {
                        font-size: 22px;
                    }
                }

                /* Tablet layout */
                @media only screen and (max-width: 1023px) {
                    h1 {
                        margin-bottom: 30px;
                    }
                }

                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    search-desktop {
                        display: none;
                    }
                    
                    #container {
                        /* height: 246px; */
                    }

                    h1 {
                        font-size: 24px;
                    }

                    h2 {
                        font-size: 14px;
                    }

                    #store {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin: 20px 0 30px 0;
                    }

                    #store img {
                        width: 100%;
                        height: auto;
                        margin: 0 auto;
                    }

                    #store-header {
                        font-size: 24px;
                        width: auto;
                    }

                    form {
                        grid-template-columns: 1fr 1fr;
                        grid-template-areas:
                            'occasion recipient'
                            'button button';
                        padding: 16px 12px;
                        width: 100%;
                        padding: 0 6px;
                        margin-top: -50px;
                    }

                    #occasion {
                        grid-area: occasion;
                        margin-right: 20px;
                    }

                    #recipient {
                        grid-area: recipient;
                        margin: 16px 0 0 0;
                    }

                    form select {
                        margin: 16px 0;
                        font-size: 12px;
                    }

                    form button {
                        grid-area: button;
                        width: 100%;
                        margin-bottom: 16px;
                    }
                }
            </style>

            <div id='container'>
                <section id='text-content'>
                    <h1>${this.dataset.titleMain || ''}</h1>
                    <h2>${this.dataset.titleSecondary || ''}</h2>
                </section>

                <div id='collection-tabs'></div>

                <search-desktop data-page='${this.dataset.page}'></search-desktop>

                <div id='store'>
                    <img src='' alt='merchandise graphic'>
                    <div id='header-detail'>
                        <p id='store-header'>Find your perfect cartoon gift</p>
                        <form>
                            <select id='occasion'>
                                <option value="">Occasion</option>
                            </select>
                            <select id='recipient'>
                                <option value="">Recipient</option>
                            </select>
                            <button>Go</button>
                        </form>
                    </div>
                </div>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        if (this.dataset.page === 'home' && !this.mobile) {
            // Homepage layout
            (this.shadowRoot?.querySelector('#collection-tabs') as HTMLElement).innerHTML = /*html*/ `
                <a href='https://www.cartoonstock.com/collections/published-in-the-new-yorker'><img class='collection-logo' src='https://assets.cartoonstock.com/images/collection_tabs/TNY.png' alt='the new yorker logo'></a>
                <a href='https://www.cartoonstock.com/collections/published-in-wired'><img class='collection-logo' src='https://assets.cartoonstock.com/images/collection_tabs/WRD.png' alt='wired logo'></a>
                <a href='https://www.cartoonstock.com/collections/published-in-the-wall-street-journal'><img class='collection-logo' src='https://assets.cartoonstock.com/images/collection_tabs/WSJ.png' alt='wall street journal logo'></a>
                <a href='https://www.cartoonstock.com/collections/air-mail'><img class='collection-logo' src='https://assets.cartoonstock.com/images/collection_tabs/AIR.png' alt='air mail logo'></a>
                <a href='https://www.cartoonstock.com/collections/barrons'><img class='collection-logo' src='https://assets.cartoonstock.com/images/collection_tabs/BAR.png' alt='barons logo'></a>
            `;
            (this.shadowRoot?.querySelector('#collection-tabs') as HTMLElement).style.margin = '40px 0 60px 0'
        } else if (this.dataset.page === 'store') {
            // Build dropdown menus
            axios.get('https://api2.cartoonstock.com/search/tags').then(res => {
                let html = '<option value="">Occasion</option>'
                res.data.occasions.forEach((occasion:{name:string, slug:string}) => {
                    html += /*html*/ `
                        <option value='${occasion.slug}'>${occasion.name}</option>
                    `
                });
                (this.shadowRoot?.querySelector('#occasion') as HTMLSelectElement).innerHTML = html

                html = '<option value="">Recipient</option>'
                res.data.recipients.forEach((recipient:{name:string, slug:string}) => {
                    html += /*html*/ `
                        <option value='${recipient.slug}'>${recipient.name}</option>
                    `
                });
                (this.shadowRoot?.querySelector('#recipient') as HTMLSelectElement).innerHTML = html
            });

            // Store page layout
            (this.shadowRoot?.querySelector('#text-content') as HTMLElement).style.display = 'none';
            (this.shadowRoot?.querySelector('#collection-tabs') as HTMLElement).style.display = 'none';

            (this.shadowRoot?.querySelector('#store img') as HTMLImageElement).src = this.mobile ? 'https://assets.cartoonstock.com/images/homepage-store/store-header-mobile.png' : 'https://assets.cartoonstock.com/images/homepage-store/store-header.png';
            this.shadowRoot?.querySelector('form')?.addEventListener('submit', e => this.submitStore(e));

            (this.shadowRoot?.querySelector('#store') as HTMLElement).style.display = 'flex';
        }
    }

    submitStore(e:Event) {
        e.preventDefault()

        const occasion = (this.shadowRoot?.querySelector('#occasion') as HTMLSelectElement).value !== '' ? (this.shadowRoot?.querySelector('#occasion') as HTMLSelectElement).value : null
        const recipient = (this.shadowRoot?.querySelector('#recipient') as HTMLSelectElement).value !== '' ? (this.shadowRoot?.querySelector('#recipient') as HTMLSelectElement).value : null

        if (!occasion && !recipient) return
        else {
            let url = '/search?type=store'
    
            if (occasion) url += `&store_occasion=${occasion}`
            if (recipient) url += `&store_recipient=${recipient}`
    
            location.href = url
        }
    }
})
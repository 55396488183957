import '../components/menu-mobile-dropdown'

customElements.define('menu-mobile', class extends HTMLElement {
    constructor() {
        super()

        const template = document.createElement('template')
        template.innerHTML = /*html*/ `
            <style>
                #container {
                    font-family: var(--font-main);
                    display: none;
                    flex-direction: column;
                    width: 100%;
                    position: fixed;
                    bottom: 0;
                    z-index: 1;
                    overflow: hidden;
                    height: auto;
                    transition: all 0.5s ease-in-out;
                }
                #links {
                    display: flex;
                    flex-direction: column;
                    background-color: #505050;
                    padding: 15px 30px;
                }
                #links a {
                    color: #fff;
                    margin: 15px 0;
                    text-decoration: none;
                }
                button {
                    font-family: var(--font-main);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 90px;
                    background-color: #3d3d3d;
                    border: none;
                    color: #fff;
                    outline: none;
                    font-size: 16px;
                }
                img {
                    margin-bottom: 5px;
                    filter: invert(100%) sepia(8%) saturate(26%) hue-rotate(263deg) brightness(107%) contrast(100%);
                }
                /* Tablet only layout */
                @media only screen and (min-width: 768px) and (max-width: 1023px) {
                    #container{
                        top: 0;
                        right: 0;
                        width: 414px;
                        /* box-shadow: 3px 5px 11px 5px #00000020; */
                        margin-left: 10px;
                        transform: translateX(414px);
                    }
                    button, #links {
                        background-color: #fafafa;
                    }
                    #links {
                        box-shadow: 0 5px 11px -5px #00000020;
                    }
                    #links a {
                        color: #3d3d3d;
                    }
                    #close {
                        height: 80px;
                        color: #3d3d3d;
                        align-items: flex-end;
                        z-index: 1;
                        padding-right: 30px;
                        order: -1;
                    }
                    img {
                        margin: 0 12px 5px 0;
                        filter: invert(22%) sepia(0%) saturate(1529%) hue-rotate(136deg) brightness(102%) contrast(96%);
                    }
                    menu-mobile-dropdown {
                        background-color: yellow;
                    }
                }
                /* Mobile layout */
                @media only screen and (max-width: 767px) {
                    #container {
                        width: 100%;
                        transform: translateY(700px);
                    }
                }
            </style>
            <div id='container'>
                <menu-mobile-dropdown
                    data-name='Cartoons'
                    data-options='[
                        {
                            "name": "Topical",
                            "url": "/curated-topical"
                        },
                        {
                            "name": "Curated",
                            "url": "/curated"
                        },
                        {
                            "name": "Collections",
                            "url": "/collections"
                        },
                        {
                            "name": "Cartoonists",
                            "url": "/cartoonists"
                        },
                        {
                            "name": "Categories",
                            "url": "/categories"
                        }
                    ]'
                ></menu-mobile-dropdown>
                <menu-mobile-dropdown
                    data-name='Store'
                    data-options='[
                        {
                            "name": "Gift Ideas",
                            "url": "/gift-ideas"
                        },
                        {
                            "name": "Occasions",
                            "url": "/TODO"
                        },
                        {
                            "name": "Categories",
                            "url": "/categories"
                        },
                        {
                            "name": "Products",
                            "url": "/TODO"
                        },
                        {
                            "name": "See More",
                            "url": "/TODO"
                        }
                    ]'
                ></menu-mobile-dropdown>
                <!-- <menu-mobile-dropdown
                    data-name='Blog'
                    data-options='["Caption Contest", "Bob&apos;s Cartoon Lounge", "Anatomy of a Cartoon", "See More"]'
                    data-last='true'
                ></menu-mobile-dropdown> -->
                
                <div id='links'>
                    <a href='/contact'>Contact</a>
                    <a href='/pricing'>Pricing</a>
                    <a href='/contact'>Help</a>
                </div>
                <button id='close'><img src='https://assets.cartoonstock.com/images/close.svg' alt='close menu'>Close</button>
            </div>
        `

        this.attachShadow({mode:'open'}).appendChild(template.content)
    }

    connectedCallback() {
        this.shadowRoot?.querySelector('#close')?.addEventListener('click', () => {
            if (getComputedStyle((this.shadowRoot?.querySelector('#close') as HTMLElement)).order == '-1') {
                this.close('tablet')
            } else {
                this.close('mobile')
            }
        });

        this.shadowRoot?.host.addEventListener('click', (e:any) => {
            if (e.path[0] == this.shadowRoot?.host || e.path[0].id == 'container') {
                this.close('tablet')
            }
        })
    }
    
    set open(display:string) {
        const container = (this.shadowRoot?.querySelector('#container') as HTMLElement);
        (this.shadowRoot?.host as any).classList.add('background')
        container.style.display = 'flex'
        if (display == 'mobile') {
            container.style.transform = 'translateY(700px)';
            if (this.dataset.open === 'false') {
                (this.shadowRoot?.querySelector(`[data-name="${this.dataset.page}"]`) as any).open
            }
            setTimeout(() => {
                container.style.transform = 'translateY(0px)'
            }, 20)
        } else {
            container.style.transform = 'translateX(414px)';
            if (this.dataset.open === 'false') {
                (this.shadowRoot?.querySelector(`[data-name="${this.dataset.page}"]`) as any).open
            }
            setTimeout(() => {
                container.style.transform = 'translateX(0px)'
            }, 20)
        }
    }

    close(display:string) {
        const container = (this.shadowRoot?.querySelector('#container') as HTMLElement);
        (this.shadowRoot?.host as any).classList.remove('background')
        if (display == 'mobile') {
            container.style.transform = 'translateY(700px)'
        } else {
            container.style.transform = 'translateX(414px)'
        }
        setTimeout(() => {
            container.style.display = 'none'
        }, 500)
    }
})